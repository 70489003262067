import {Injectable} from '@angular/core';
import {Notification, Level} from './notification'
import {LocalisationService} from "../localisation/localisation.service";

@Injectable()

/**
 * notification service
 * */
export class NotificationService {

    //<editor-fold desc="PROPERTIES">
    /** currently notifications */
    public notifications: Notification[] = [];
    //</editor-fold>

    //<editor-fold desc="CONSTRUCTOR">
    /**
     * constructs a notification service
     * */
    constructor(private _localisationService: LocalisationService){

        // test notification
        //this.addNotification(new Notification('Test error message', Level.Error, ['Jan'], 10));
    }
    //</editor-fold>

    //<editor-fold desc="METHODS">
    /**
     * adds a notification
     * @param notification {Notification}
     * */
    public addNotification(notification: Notification){

        // localise notification key
        notification.text = this._localisationService.getText(notification.key, notification.params);

        // remove message after specified duration (8000ms default)
        setTimeout(e => {

            // remove
            this.removeNotification(notification);
        }, notification.duration * 1000 || 8000);

        // add the notification
        this.notifications.push(notification);
    }

    /***
     * removes a notification
     * @param notification {Notification} to demove
     */
    public removeNotification(notification: Notification){

        var index = this.notifications.indexOf(notification);
        this.notifications.splice(index, 1);
    }
    //</editor-fold>
}