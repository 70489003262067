import {Component, ViewEncapsulation, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router";
import {SocketService} from "../../infrastructure/socket/socket.service";
import {SessionService} from "../../infrastructure/session/session.service";
import {NavigationComponent} from "../navigation/navigation.component";
import {LocalisationPipe} from "../../infrastructure/pipes/localisation.pipe";
import {ProductService} from "../../infrastructure/server/product.service";
import {Product} from "../../infrastructure/models/product.model";
import {CategoryService} from "../../infrastructure/server/category.service";
import {Category} from "../../infrastructure/models/category.model";
import {InventoryListService} from "../../infrastructure/server/inventoryList.service";
import {InventoryProduct} from "../../infrastructure/models/inventoryProduct.model";
import {MatTableDataSource} from '@angular/material';
import { FilterService } from 'src/app/infrastructure/filter/filter.service';
import { filter } from 'rxjs/operators';

import _ from "lodash";
import * as jsPDF from "jspdf";

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'inventoryProducts',
    templateUrl: './inventory.tmpl.html',
    styleUrls: ['./inventory.sass']
})

/**
 * products component
 * */
export class InventoryComponent implements OnInit {

    public _inventoryID : string;
    private filter: string;
    private filterObject : ProductFilter = new ProductFilter();
    displayedColumns = ['product_name', 'product_description', 'product_supplier', 'product_quantity', 'product_inv_box', 'product_inv_unite', 'product_inv_rest', 'product_price'];
    dataSource = new MatTableDataSource();
    private isUserAllowedWriteBoolean: boolean = false;
    /**
     * constructs an application component
     * */
    constructor(private _socket: SocketService,
                private _categoryService: CategoryService,
                private _inventoryListService: InventoryListService,
                private _filterService: FilterService,
                private _sessionService: SessionService,
                private _route: ActivatedRoute,
                private _router: Router){}

    public ngOnInit(){

        // subscribe to url param
        this._route.params.subscribe(params => {

            this._inventoryID = params['id'];

            if(this._sessionService.isLoggedIn()) {
                // check if cookie set
                this._sessionService.user = this._sessionService.getUser();
                // create a socket connection
                this._sessionService.socketConnect(this._sessionService.user );

                if(!this.isUserAllowedRead()){
                    setTimeout(time => {
                        this._router.navigate(['login']);
                    }, 100);
                }
                setTimeout(time => {
                    this._inventoryListService.loadInventories().subscribe(inventories => this.setUserAllowedWriteBoolean());
                    //this._inventoryListService.loadInventoryProducts(this._inventoryID).subscribe();
                    this._categoryService.loadCategories().subscribe();
                }, 500);
            }else{
                // redirect to login
                setTimeout(time => {
                    this._router.navigate(['login']);
                }, 100);
            }
        });
        this._inventoryListService.loadInventoryProducts(this._inventoryID).subscribe(data => this.dataSource.data = data);
        this._filterService.filter.subscribe(filter => this.applyNameFilter(filter));
        this._filterService.category.subscribe(category => this.applyCatFilter(category));

        this.dataSource.filterPredicate = (data: InventoryProduct, filter: string) => {
            var filters : string[] = filter.split("|");
            return (((data.product_name.trim().toLowerCase().indexOf(filters[0]) != -1) || (data.sup_name && data.sup_name.trim().toLowerCase().indexOf(filters[0]) != -1)) && (filters[1] == "" || filters[1] == undefined || data.category.toString() == filters[1]));
           };
    }

    applyNameFilter(value: string){
        this.filterObject.name = value.trim().toLowerCase();
        this.applyFilter(this.filterObject);
    }

    applyCatFilter(value: string){
        this.filterObject.cat = value;
        this.applyFilter(this.filterObject);
    }

    applyFilter(productFilter: ProductFilter) {
        var filterString : string = "";
        filterString = productFilter.name+"|"+productFilter.cat;
        this.dataSource.filter = filterString;
    }

    /**
     *
     * 
     */
    public getProducts() : InventoryProduct[] {
        var result : InventoryProduct[] = [];
        if(!this._inventoryListService.inventoryProducts){
            this._router.navigate(['InventoryList']);
        }else{
            if ((this._filterService.categoryid == "" || this._filterService.categoryid == "undefined") && (!this._filterService.filterValue || this._filterService.filterValue == "")){
                for (var i = 0; i < this._inventoryListService.inventoryProducts.length; i++){
                    result.push(this._inventoryListService.inventoryProducts[i]);
                    if (i > 200){
                        return result;
                    }
                }
                return result;
            }
            for (var i = 0; i < this._inventoryListService.inventoryProducts.length; i++){
                if ((!this._filterService.categoryid || this._inventoryListService.inventoryProducts[i].category as any == this._filterService.categoryid) && ((!this._filterService.filterValue || this._filterService.filterValue == "") || this._inventoryListService.inventoryProducts[i].product_name.indexOf(this._filterService.filterValue) >= 0) ){
                    result.push(this._inventoryListService.inventoryProducts[i]);
                }
            }
            return result;
        }
    };

    public inventoryPrice(product: InventoryProduct){
        var price = 0;
        var price_per_kg_or_l = this._inventoryListService.pricePerKg(product);
        var price_per_unit = this._inventoryListService.pricePerUnit(product);
        var price_per_box = this._inventoryListService.pricePerBox(product);

        price = price + price_per_kg_or_l * product.inv_rest;
        price = price + price_per_unit * product.inv_unit;
        price = price + price_per_box * product.inv_box;
        return price;
    }

    public updateInventory(product: InventoryProduct){
        this._inventoryListService.updateInventoryProduct(product).subscribe();
    }

    public printPDF() {

        var doc = new jsPDF();
        doc.setFont("arial narrow");
        var selectedCategories;// = [category];
        var pageNR = 1;
        //selectedCategories = [];
        selectedCategories = this._categoryService.getSortedCategories();
        /*for (var i = 0; i <  this._categoryService.categories.length; i++) {
            selectedCategories.push( this._categoryService.categories[i]);
        }*/
        doc.setFontSize(10);
        doc.text(190, 290, ""+pageNR);
        pageNR++;

        var firstCat = true;
        for (var j = 0; j < selectedCategories.length;j++) {
            var catName = selectedCategories[j].name.toString().replace(new RegExp('œ', 'g'),'oe') ;
            var filteredProducts = this._inventoryListService.getProductsOfCategory(selectedCategories[j]);
            var productsPerPage = 40;
            if (filteredProducts.length > 0){
                if (!firstCat) {
                    doc.addPage();
                    doc.setFontSize(10);
                    doc.text(190, 290, ""+pageNR);
                    pageNR++;
                }
                firstCat = false;


                for (var i = 0; i < filteredProducts.length; i++) {
                    if (i % productsPerPage == 0) {
                        doc.setFontSize(22);
                        if (i != 0) {
                            doc.addPage();
                            doc.setFontSize(10);
                            doc.text(190, 290, ""+pageNR);
                            pageNR++;
                        }
                        doc.text(20, 20, 'Inventaire: ' + catName);
                        doc.setFontSize(10);
                        doc.text(140, 30, 'nb Colis');
                        doc.text(160, 30, 'nb Unites');
                        doc.text(180, 30, 'nb Rest');
                    }
                    doc.setFontSize(10);
                    let productDescString = "";
                    if (filteredProducts[i].product_description){
                        productDescString =  " (" + filteredProducts[i].product_description.toString().replace(new RegExp('œ', 'g'),'oe')  +", "+filteredProducts[i].box + " * " + filteredProducts[i].quantity + " " + filteredProducts[i].unit +")"
                    }
                    doc.text(20, (i % productsPerPage) * 6 + 40, filteredProducts[i].product_name.toString().replace(new RegExp('œ', 'g'),'oe') + productDescString);

                    var lineY = (i % productsPerPage) * 6 + 40 + 1;
                    doc.setLineWidth(0.5);
                    doc.line(20, lineY, 130, lineY);

                    /*doc.setLineWidth(0.5);
                    var lineY = lineY - 0.5;
                    doc.line(140, lineY, 150, lineY);*/


                    doc.line(135, lineY, 150, lineY);
                    doc.line(135, lineY-5, 150, lineY-5);
                    doc.line(135, lineY-5, 135, lineY);
                    doc.line(150, lineY-5, 150, lineY);

                    doc.text(148, (i % productsPerPage) * 6 + 40, 'c');
                    if (filteredProducts[i].box > 1){
                        doc.text(168, (i % productsPerPage) * 6 + 40, 'u');
                        doc.line(155, lineY, 170, lineY);
                        doc.line(155, lineY-5, 170, lineY-5);
                        doc.line(155, lineY-5, 155, lineY);
                        doc.line(170, lineY-5, 170, lineY);
                    }
                    if (filteredProducts[i].price_unit == 'kg' || filteredProducts[i].unit == 'kg' || filteredProducts[i].price_unit == 'l' || filteredProducts[i].unit == 'l'){
                        doc.text(188, (i % productsPerPage) * 6 + 40, 'kg/l');
                        doc.line(175, lineY, 195, lineY);
                        doc.line(175, lineY-5, 195, lineY-5);
                        doc.line(175, lineY-5, 175, lineY);
                        doc.line(195, lineY-5, 195, lineY);
                    }
                }
            }
        }
        var name = "";
        if (selectedCategories.length > 1){
            name = 'Inventaire.pdf'
        }else{
            name = 'Inventaire_'+selectedCategories[0].name+'.pdf'
        }
        doc.save(name);
    }

    public printPDFWithPrice() {
        var splitRegex = /\r\n|\r|\n/g;
        jsPDF.API.textEx = function (text, x, y, hAlign, vAlign) {
            var fontSize = this.internal.getFontSize() / this.internal.scaleFactor;
            y += 0.75;
            // As defined in jsPDF source code
            var lineHeightProportion = 1.15;

            var splittedText = null;
            var lineCount = 1;
            if (vAlign === 'middle' || vAlign === 'bottom' || hAlign === 'center' || hAlign === 'right') {
                splittedText = typeof text === 'string' ? text.split(splitRegex) : text;

                lineCount = splittedText.length || 1;
            }

            // Align the top
            y += fontSize * (2 - lineHeightProportion);

            if (vAlign === 'middle')
                y -= (lineCount / 2) * fontSize;
            else if (vAlign === 'bottom')
                y -= lineCount * fontSize;

            if (hAlign === 'center' || hAlign === 'right') {
                var alignSize = fontSize;
                if (hAlign === 'center')
                    alignSize *= 0.5;

                if (lineCount > 1) {
                    for (var iLine = 0; iLine < splittedText.length; iLine++) {
                        this.text(splittedText[iLine], x - this.getStringUnitWidth(splittedText[iLine]) * alignSize, y);
                        y += fontSize;
                    }
                    return this;
                }
                x -= this.getStringUnitWidth(text) * alignSize;
            }

            this.text(text, x, y);
            return this;
        };



        var doc = new jsPDF();
        doc.setFont("arial narrow");
        var pageNR = 1;
        var selectedCategories;// = [category];

        var titleHeight = 24;
        var lineHeight = 6;
        var actualY = 0;
        //selectedCategories = [];
        selectedCategories = this._categoryService.getSortedCategories();
        /*for (var i = 0; i <  this._categoryService.categories.length; i++) {
         selectedCategories.push( this._categoryService.categories[i]);
         }*/

        doc.setFontSize(10);
        doc.text(20, 290, this._inventoryListService.selectedInventory.name);
        doc.text(190, 290, ""+pageNR);
        pageNR++;
        var catTotal = {};
        for (var j = 0; j < selectedCategories.length;j++) {
            var catName = selectedCategories[j].name.toString().replace(new RegExp('œ', 'g'),'oe') ;
            var filteredProducts = this._inventoryListService.getProductsOfCategoryWithQuantity(selectedCategories[j]);

            if (filteredProducts.length > 0){

                var pageHeight= doc.internal.pageSize.getHeight();
                if (actualY+titleHeight+50 >= pageHeight) {
                    actualY = 20;
                    doc.addPage();
                    doc.setFontSize(10);
                    doc.text(20, 290, this._inventoryListService.selectedInventory.name);
                    doc.text(190, 290, ""+pageNR);
                    pageNR++;
                }
                doc.setFontSize(18);
                actualY=actualY+16;
                doc.text(20, actualY, 'Inventaire: ' + catName);
                doc.setFontSize(10);
                actualY=actualY+10;
                doc.text(120, actualY, 'nb Colis');
                doc.text(140, actualY, 'nb Unites');
                doc.text(160, actualY, 'nb Rest');
                doc.text(180, actualY, 'Prix');


                for (var i = 0; i < filteredProducts.length; i++) {
                    var pageHeight= doc.internal.pageSize.getHeight();
                    if (actualY+titleHeight >= pageHeight) {

                        doc.addPage();
                        doc.setFontSize(10);
                        doc.text(20, 290, this._inventoryListService.selectedInventory.name);
                        doc.text(190, 290, ""+pageNR);
                        pageNR++;

                        actualY=20;
                        doc.setFontSize(18);
                        doc.text(20, actualY, 'Inventaire: ' + catName);
                        actualY=actualY+10;
                        doc.setFontSize(10);
                        doc.text(120, actualY, 'nb Colis');
                        doc.text(140, actualY, 'nb Unites');
                        doc.text(160, actualY, 'nb Rest');
                        doc.text(180, actualY, 'Prix');
                    }

                    var pageHeight= doc.internal.pageSize.getHeight();
                    actualY+= lineHeight;
                    if (actualY >= pageHeight) {
                        actualY = 20;
                        doc.addPage();
                        doc.setFontSize(10);
                        doc.text(20, 290, this._inventoryListService.selectedInventory.name);
                        doc.text(190, 290, ""+pageNR);
                        pageNR++;
                    }
                    doc.setFontSize(10);
                    let productDescString = "";
                    if (filteredProducts[i].product_description){
                        productDescString =  " (" + filteredProducts[i].product_description.toString().replace(new RegExp('œ', 'g'),'oe')  +", "+filteredProducts[i].box + " * " + filteredProducts[i].quantity + " " + filteredProducts[i].unit +")"
                    }
                    doc.text(20, actualY, filteredProducts[i].product_name.toString().replace(new RegExp('œ', 'g'),'oe') + productDescString);

                    var lineY = actualY + 1;
                    doc.setLineWidth(0.5);
                    doc.line(20, lineY, 195, lineY);
                    doc.setLineWidth(0.5);


                   // doc.text(128, (i % productsPerPage) * 6 + 40, filteredProducts[i].inv_box +'c');
                    //doc.text(148, (i % productsPerPage) * 6 + 40, filteredProducts[i].inv_unit +'u');
                    //doc.text(168, (i % productsPerPage) * 6 + 40, filteredProducts[i].inv_rest +'kg/l');
                   // doc.text(188, (i % productsPerPage) * 6 + 40, this.inventoryPrice(filteredProducts[i]) +'Fr');
                    doc.textEx(filteredProducts[i].inv_box +'c',128, actualY, 'right', 'bottom');
                    doc.textEx(filteredProducts[i].inv_unit +'u',148, actualY, 'right', 'bottom');
                    doc.textEx(filteredProducts[i].inv_rest +'kg/l',168, actualY, 'right', 'bottom');
                    if (!catTotal[selectedCategories[j].inv_nr.sort]){
                        catTotal[selectedCategories[j].inv_nr.sort] = {};
                        catTotal[selectedCategories[j].inv_nr.sort].value = 0;
                        catTotal[selectedCategories[j].inv_nr.sort].name = selectedCategories[j].inv_nr.inv_nr;
                        catTotal[selectedCategories[j].inv_nr.sort].title = selectedCategories[j].inv_nr.name;
                        catTotal[selectedCategories[j].inv_nr.sort].sort = selectedCategories[j].inv_nr.sort;
                    }
                    catTotal[selectedCategories[j].inv_nr.sort].value += this.inventoryPrice(filteredProducts[i]);
                    catTotal[selectedCategories[j].inv_nr.sort].name = selectedCategories[j].inv_nr.inv_nr;
                    catTotal[selectedCategories[j].inv_nr.sort].title = selectedCategories[j].inv_nr.name;
                    catTotal[selectedCategories[j].inv_nr.sort].sort = selectedCategories[j].inv_nr.sort;
                    doc.textEx(this.inventoryPrice(filteredProducts[i]).toFixed(2) +' SFr.',188, actualY, 'right', 'bottom');
                }
            }
        }
        doc.addPage();
        doc.setFontSize(10);
        doc.text(20, 290, this._inventoryListService.selectedInventory.name);
        doc.text(190, 290, ""+pageNR);
        doc.setFontSize(22);
        doc.text(20, 30, this._inventoryListService.selectedInventory.name);
        doc.setFontSize(10);
        var totalValue = 0;
        var lineIndex = 0;
        for (var _i = 0; _i < 100; _i++) {
            var total = catTotal[_i];
            if (total){
                doc.text(25, (lineIndex) * 6 + 60, total.name );
                doc.text(40, (lineIndex) * 6 + 60, total.title);
                doc.text(130, (lineIndex) * 6 + 60, 'SFr.');
                doc.textEx(total.value.toFixed(2) ,160, (lineIndex) * 6 + 60, 'right', 'bottom');
                totalValue+= total.value;
                lineIndex++;
            }
        }

       /* var i = 0;

        for (var total in catTotal) {
            doc.text(20, (i) * 6 + 40, catTotal[total].name +" - "+catTotal[total].title);
            doc.textEx(catTotal[total].value.toFixed(2) +' Fr',100, (i) * 6 + 40, 'right', 'bottom');
            totalValue+= catTotal[total].value;
            i++;
        }*/

        doc.setFontType("bold");
        doc.text(110, (lineIndex+1) * 6 + 60, 'Total');
        doc.text(130, (lineIndex+1) * 6 + 60, 'SFr.');
        doc.textEx(totalValue.toFixed(2) ,160, (lineIndex+1) * 6 + 60, 'right', 'bottom');
        doc.setFontType("normal");

        doc.text(80, (lineIndex+4) * 6 + 60, 'Fribourg, le ');

        doc.save(this._inventoryListService.selectedInventory.name+'.pdf');
    }

    public dynamicSort(property: string){
        var sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    public isUserAllowedRead(){
        return this._sessionService.user.superAdmin|| this._sessionService.user.inventoryWrite|| this._sessionService.user.inventoryRead;
    }

    public isUserAllowedWrite(){
        return this.isUserAllowedWriteBoolean;
    }

    public setUserAllowedWriteBoolean(){
        var instance = this;
        var readOnly = true;
        this._inventoryListService.inventories.forEach(function (inventory){
            if (inventory._id == instance._inventoryID){
                inventory.users.forEach(function (user){
                    if (user.userId as any == instance._sessionService.user._id){
                        readOnly = user.readOnly;
                    }
                });
            }
        });
        this.isUserAllowedWriteBoolean = !readOnly && (this._sessionService.user.superAdmin || this._sessionService.user.inventoryWrite);
    }

    public onLeft(event, index){
        event.stopPropagation();
        if (index % 10 == 1){
            index = index - 10 + 2;
        }else{
            index = index - 1;
        }
        let input = document.getElementById("A"+index) as HTMLInputElement;
        input.focus();
        setTimeout(() => { input.select(); }, 50);

    }

    public onRight(event, index){
        event.stopPropagation();
        if (index % 10 == 3){
            index = index + 10 - 2;
        }else{
            index = index + 1;
        }
        let input = document.getElementById("A"+index) as HTMLInputElement;
        input.focus();
        setTimeout(() => { input.select(); }, 50);
    }

    public onUp(event, index){
        event.stopPropagation();
        index = index - 10;
        let input = document.getElementById("A"+index) as HTMLInputElement;
        input.focus();
        setTimeout(() => { input.select(); }, 50);
    }

    public onDown(event, index){
        event.stopPropagation();
        index = index + 10;
        let input = document.getElementById("A"+index) as HTMLInputElement;
        input.focus();
        setTimeout(() => { input.select(); }, 50);
    }

    public selectAllContent($event) {
        $event.target.select();
    }

}

export class ProductFilter {
    name: string;
    cat: string;
  }