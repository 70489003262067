import {Injectable} from '@angular/core';
import {Headers} from "@angular/http";
import {Router} from "@angular/router";
import {SocketService} from "../socket/socket.service";
import {Http} from "@angular/http";
import {User} from "../models/user.model";
import {ConfigService} from "../config/confg.service";
import {Observable} from "rxjs";
import {Observer} from "rxjs";
import 'rxjs/Rx';
import {CookieService} from "../cookies/cookie.service";
import * as CryptoJS from "crypto-js";

@Injectable()

/**
 * event service enables event CRUD
 * */
export class SessionService {

    //<editor-fold desc="PROPERTIES">
    public user: User;
    //</editor-fold>

    //<editor-fold desc="CONSTRUCTOR">
    /**
     * constructs a SocketService
     * @param _configService {ConfigService}
     * */
    constructor(private _http: Http,
                private _config: ConfigService,
                private _router: Router,
                private _cookie: CookieService,
                private _socket: SocketService){}
    //</editor-fold>

    //<editor-fold desc="METHODS">
    /**
     * logs in a user
     * @param user {User} to log in
     * @return Observable1
     * */
    public login(user: User) : Observable<User>{

        var instance = this;

        // implement as observable (instead of promise)
        return new Observable(observer => {

            // create http load
            var load =
            {
                name: user.name,
                password: CryptoJS.MD5(user.password).toString(CryptoJS.enc.Hex)
            };

            var headers = new Headers();
            headers.append('Content-Type', 'application/json');

            // log in
            this._http.post(this._config.BASE_URL + '/login', JSON.stringify(load), {headers:headers})

                // map the result
                .map(res => res.json())

                // wait for resolve
                .subscribe(dbUser => {

                    // connect to socket
                    instance._socket.connect(dbUser);

                    // store the user for faster access
                    instance.user = dbUser;

                    // store as cookie
                    instance._cookie.setCookie('user', JSON.stringify(dbUser), 10);

                    // inform observer
                    observer.next(dbUser);
                }, err => {

                    // emit error
                    observer.error(err._body);
                });
        });
    }

    /**
     * attempts to create a web socket connection
     * @param user {User} to create the socket connection for
     * */
    public socketConnect(user){

        // connects to the web-socket
        this._socket.connect(user);
    }

    /**
     * logs out the current user
     * */
    public logout(){

        // clear the cookie
        this._cookie.deleteCookie('user');

        // navigate to login
        this._router.navigate(['login']);
        location.reload();
    }

    /**
     * checks if a user is currently logged in
     * @return {boolean} true if logged in, false otehrwise
     * */
    public isLoggedIn() : boolean{

        // user is logged in if cookie is set
        return this._cookie.getCookie('user') != null;
    }

    /**
     * gets the logged in user
     * @return user {User}
     * */
    public getUser() : User{

        return JSON.parse(this._cookie.getCookie('user'));
    }
    //</editor-fold>
}