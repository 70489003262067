
import {Product} from "./product.model";
import {Recipe} from "./recipe.model";
import {User} from "./user.model";

/***
 *  patient class
 */
export  class Ingredient {
    public product: Product;
    public recipe: Recipe;
    public text: string;
    public quantity: number;
    public unit:  string;
    public order:  number;

}

export class InventoryUser{
    public userId: User;
    public readOnly: boolean;
}




