import {Injectable} from '@angular/core';
import {Http, Headers} from "@angular/http";

@Injectable()

/**
 * Application security service
 * */
export class LocalisationService {

    /**
     * all available languages
     * @type {string[]}
     */
    public languages : any[] = [
        {label: 'Deutsch', code:'de-ch'},
        {label: 'Français', code:'fr-ch'},
        {label: 'English', code:'en-gb'}
    ];

    //<editor-fold desc="PROPERTIES">
    /**
     * currently selected language
     * @type {string}
     */
    public currentLanguage: string = this.languages[1].code;

    /**
     * dictionary holds list (hash map) of all translations
     */
    public dictionary:any;
    //</editor-fold>

    //<editor-fold desc="CONSTRUCTOR">
    /**
     * constructs a Date Service
     * */
    constructor(private _http: Http){

        var instance = this;

        // load dictionary
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');

        // load dictionary over http
        this._http.get('resources/localisation/dictionary.json', {headers:headers})

            // map the result
            .map(res => res.json())

            // wait for resolve
            .subscribe(dictionary => {

                // assign dictionary
                instance.dictionary = dictionary;
            });
    }
    //</editor-fold>

    //<editor-fold desc="METHODS">
    /**
     * gets a localised string
     * */
    public getText(key: string, params?: any){

        var instance = this;

        // abort if dictionary not yet loaded
        if(instance.dictionary == undefined || key == ''){
            return key;
        }

        // get from JSON text DB
        var text = instance.dictionary[instance.currentLanguage][key];

        // replace parameters in string if defined
        if(params && params.length > 0){

            // iterate params
            for(var i = 0; i < params.length; i++){
                
                // replace
                text = text.replace('$$', params[i])
            }
        }

        // return key if no value found
        return text ? text : key;
    }
    //</editor-fold>
}