import {ChangeDetectionStrategy, Component, ViewEncapsulation, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {SocketService} from "../../infrastructure/socket/socket.service";
import {SessionService} from "../../infrastructure/session/session.service";
import {ProductService} from "../../infrastructure/server/product.service";
import {Product} from "../../infrastructure/models/product.model";
import {ProductDialogComponent} from "./dialog/product-dialog.component";
import {CategoryService} from "../../infrastructure/server/category.service";
import {Category} from "../../infrastructure/models/category.model";
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {MatTableDataSource} from '@angular/material';
import _ from "lodash";
import { FilterService } from 'src/app/infrastructure/filter/filter.service';
import { filter } from 'rxjs/operators';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'products',
    templateUrl: './products.tmpl.html',
    styleUrls: ['./products.sass']
})

/**
 * products component
 * */
export class ProductsComponent implements OnInit{
    public _showDialog : boolean = false;

    public _selectedProduct: Product = new Product();
    private filter: string;
    private filterObject : ProductFilter = new ProductFilter();
    displayedColumns = ['product_name', 'product_description', 'product_supplier', 'product_category', 'product_quantity', 'product_price_per_unit', 'edit'];
    dataSource = new MatTableDataSource();

    /**
     * constructs an application component
     * */
    constructor(private _socket: SocketService,
                private _productService: ProductService,
                private _sessionService: SessionService,
                private _categoryService: CategoryService,
                private _filterService: FilterService,
                private _router: Router){}

    public ngOnInit(){
        if(this._sessionService.isLoggedIn()) {
            // check if cookie set
            this._sessionService.user = this._sessionService.getUser();
            // create a socket connection
            this._sessionService.socketConnect(this._sessionService.user );
            if (!(this._sessionService.user.productRead || this._sessionService.user.productWrite || this._sessionService.user.superAdmin)){
                this._router.navigate(['login']);
            }
           
            setTimeout(time => {
                //this._mbrService.listen();
                //this._productService.loadProducts().subscribe();
            }, 100);
        }else{
            // redirect to login
            setTimeout(time => {
                this._router.navigate(['login']);
            }, 100);
        }
        this._productService.loadAllProducts().subscribe(data => this.dataSource.data = data);
        this._filterService.filter.subscribe(filter => this.applyNameFilter(filter));
        this._filterService.category.subscribe(category => this.applyCatFilter(category));
        this.dataSource.filterPredicate = (data: Product, filter: string) => {
            var filters : string[] = filter.split("|");
            return (((data.product_name.trim().toLowerCase().indexOf(filters[0]) != -1) || (data.sup_name && data.sup_name.trim().toLowerCase().indexOf(filters[0]) != -1)) && (filters[1] == "" || filters[1] == undefined || data.category._id == filters[1]));
           };
    }

    applyNameFilter(value: string){
        this.filterObject.name = value.trim().toLowerCase();
        this.applyFilter(this.filterObject);
    }

    applyCatFilter(value: string){
        this.filterObject.cat = value;
        this.applyFilter(this.filterObject);
    }

    applyFilter(productFilter: ProductFilter) {
        var filterString : string = "";
        filterString = productFilter.name+"|"+productFilter.cat;
        this.dataSource.filter = filterString;
    }


   

    /**
     *
     * @returns {Product[]}
     */
    public getProducts() : Product[] {
        return this._productService.products;
    };

    public loadProducts(state){
        this._productService.loadProducts(state).subscribe();
    }


    public openDialog(product: Product){
        if (product == null){
            if (this._selectedProduct){
                delete this._selectedProduct._id;
            }
            this._selectedProduct.sup_name = "";
            this._selectedProduct.product_number = "";
            this._selectedProduct.product_name = "";
            this._selectedProduct.product_description = "";
            this._selectedProduct.category = this._categoryService.categories[0];
            this._selectedProduct.quantity = 0;
            this._selectedProduct.unit  = "kg";
            this._selectedProduct.box = 0;
            this._selectedProduct.price_per_unit = 0;
            this._selectedProduct.price_unit  = "box";
            this._selectedProduct.inv_box = 0;
        }else{
            this._selectedProduct = _.cloneDeep(product);
        }
        this._showDialog = true;
    }

    public addOrUpdateProduct(product: Product){
        this._showDialog = false;
        this._productService.createOrUpdateProduct(product).subscribe(product => this._productService.loadAllProducts().subscribe(data => this.dataSource.data = data));
        ;
    }

    public deleteProduct(product: Product){
        this._showDialog = false;
        this._productService.removeProduct(product).subscribe(product => this._productService.loadAllProducts().subscribe(data => this.dataSource.data = data));
        
    }
    
    public getPricePerBox(product: Product){
        return this._productService.pricePerBox(product);
    }

    public getPricePerKg(product: Product){
        return this._productService.pricePerKg(product);
    }

    public getPricePerUnit(product: Product){
        return this._productService.pricePerUnit(product);
    }

    public getPricePerPriceUnit(product: Product){
        if (product.price_unit == "kg" || product.price_unit == "l"){
            return this.getPricePerKg(product);
        }else if (product.price_unit == "u"){
            return this.getPricePerUnit(product);
        }else{
            return this.getPricePerBox(product);
        }
    }
}

export class ProductFilter {
    name: string;
    cat: string;
  }