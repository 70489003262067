import {Component, ViewEncapsulation, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {SocketService} from "../../infrastructure/socket/socket.service";
import {SessionService} from "../../infrastructure/session/session.service";
import {LocalisationPipe} from "../../infrastructure/pipes/localisation.pipe";
import {ProductService} from "../../infrastructure/server/product.service";
import {Product} from "../../infrastructure/models/product.model";
import {RecipecategoryService} from "../../infrastructure/server/recipecategory.service";
import {RecipecategoryDialogComponent} from "./dialog/recipecategory-dialog.component";
import {Recipecategory} from "../../infrastructure/models/recipecategory.model";
import {InventoryNumber} from "../../infrastructure/models/inventoryNumber.model";
import {CategoryDrag} from "../../infrastructure/dragDrop/category-drag.directive";

import _ from "lodash";

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'recipecategories',
    templateUrl: './recipecategories.tmpl.html',
    styleUrls: ['./recipecategories.sass']
})

/**
 * products component
 * */
export class RecipecategoriesComponent implements OnInit {
    private _showDialog : boolean = false;

    private _selectedRecipecategory: Recipecategory = new Recipecategory();
    private _inventoryNumbers: InventoryNumber[] = [];

    /**
     * constructs an application component
     * */
    constructor(private _socket: SocketService,
                private _productService: ProductService,
                private _sessionService: SessionService,
                private _recipecategoryService: RecipecategoryService,
                private _router: Router){}

    public ngOnInit(){
        if(this._sessionService.isLoggedIn()) {
            // check if cookie set
            this._sessionService.user = this._sessionService.getUser();
            // create a socket connection
            this._sessionService.socketConnect(this._sessionService.user );
            if (!(this._sessionService.user.superAdmin)){
                this._router.navigate(['login']);
            }
            setTimeout(time => {
                //this._mbrService.listen();
                this._recipecategoryService.loadRecipecategories().subscribe();
            }, 100);
        }else{
            // redirect to login
            setTimeout(time => {
                this._router.navigate(['login']);
            }, 100);
        }
    }

    /**
     *
     * @returns {Mbr[]}
     */
    public getRecipecategories() : Recipecategory[] {
        return this._recipecategoryService.getSortedRecipecategories();
    };


    public openDialog(recipecategory: Recipecategory){
        if (recipecategory == null){
            if (this._selectedRecipecategory){
                delete this._selectedRecipecategory._id;
            }
            this._selectedRecipecategory.name = "";
        }else{
            this._selectedRecipecategory = _.cloneDeep(recipecategory);
        }
        this._showDialog = true;
    }

    public addOrUpdateRecipecategory(recipecategory: Recipecategory){
        this._showDialog = false;
        this._recipecategoryService.createOrUpdateRecipecategory(recipecategory).subscribe();
    }

    public deleteRecipecategory(recipecategory: Recipecategory){
        this._showDialog = false;
        this._recipecategoryService.removeRecipecategory(recipecategory).subscribe();
    }
    
    public order(){
        for (var i = 0; i <  this._recipecategoryService.recipecategories.length; i++) {
            this._recipecategoryService.recipecategories[i].order = i +1;
            this._recipecategoryService.createOrUpdateRecipecategory(this._recipecategoryService.recipecategories[i]).subscribe();
        }
    }
}