import {Ingredient} from "./substructures.model";
import {Company} from "./company.model";
import {Recipecategory} from "./recipecategory.model";
/**
 * User class
 * */
export class Recipe {

    // default
    public  _id: string;

    public name :  string;
    public type : string;
    public quantity : number;
    public unit :  string;
    public parts :  number;
    public description : string;
    public ingredients: Ingredient[] = [];
    public company: Company;
    public protected: boolean;
    public recipecategory: Recipecategory;
    public date : Date;
    
    constructor(){}
}


