import {Component, ViewEncapsulation, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {SocketService} from "../../infrastructure/socket/socket.service";
import {SessionService} from "../../infrastructure/session/session.service";
import {LocalisationPipe} from "../../infrastructure/pipes/localisation.pipe";
import {ProductService} from "../../infrastructure/server/product.service";
import {Product} from "../../infrastructure/models/product.model";
import {CategoryService} from "../../infrastructure/server/category.service";
import {CategoryDialogComponent} from "./dialog/category-dialog.component";
import {Category} from "../../infrastructure/models/category.model";
import {InventoryNumber} from "../../infrastructure/models/inventoryNumber.model";
import {CategoryDrag} from "../../infrastructure/dragDrop/category-drag.directive";

import _ from "lodash";

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'categories',
    templateUrl: './categories.tmpl.html',
    styleUrls: ['./categories.sass']
})

/**
 * products component
 * */
export class CategoriesComponent implements OnInit {
    private _showDialog : boolean = false;

    private _selectedCategory: Category = new Category();
    private _inventoryNumbers: InventoryNumber[] = [];

    /**
     * constructs an application component
     * */
    constructor(private _socket: SocketService,
                private _productService: ProductService,
                private _sessionService: SessionService,
                private _categoryService: CategoryService,
                private _router: Router){}

    public ngOnInit(){
        if(this._sessionService.isLoggedIn()) {
            // check if cookie set
            this._sessionService.user = this._sessionService.getUser();
            // create a socket connection
            this._sessionService.socketConnect(this._sessionService.user );
            if (!(this._sessionService.user.superAdmin)){
                this._router.navigate(['login']);
            }
            setTimeout(time => {
                //this._mbrService.listen();
                this._categoryService.loadCategories().subscribe();
                this._categoryService.loadInventoryNumbers().subscribe();
            }, 100);
        }else{
            // redirect to login
            setTimeout(time => {
                this._router.navigate(['login']);
            }, 100);
        }
    }

    /**
     *
     * @returns {Mbr[]}
     */
    public getCategories() : Category[] {
        return this._categoryService.getSortedCategories();
    };


    public openDialog(category: Category){
        if (category == null){
            if (this._selectedCategory){
                delete this._selectedCategory._id;
            }
            this._selectedCategory.name = "";
            this._selectedCategory.inv_nr = null;
        }else{
            this._selectedCategory = _.cloneDeep(category);
            if (this._selectedCategory.inv_nr){
                this._selectedCategory.inv_nr = this._selectedCategory.inv_nr._id as any;
            }
        }
        this._inventoryNumbers = this._categoryService.inventoryNumbers;
        this._showDialog = true;
    }

    public addOrUpdateCategory(category: Category){
        this._showDialog = false;
        this._categoryService.createOrUpdateCategory(category).subscribe();
    }

    public deleteCategory(category: Category){
        this._showDialog = false;
        this._categoryService.removeCategory(category).subscribe();
    }
    
    public order(){
        for (var i = 0; i <  this._categoryService.categories.length; i++) {
            this._categoryService.categories[i].order = i +1;
            this._categoryService.createOrUpdateCategory(this._categoryService.categories[i]).subscribe();
        }
    }
}