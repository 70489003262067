import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {SocketService} from "../socket/socket.service";
import {NotificationService} from "../notification/notification.service";
import {DateService} from "../date/date.service";
import {Recipecategory} from "../models/recipecategory.model";
import {InventoryNumber} from "../models/inventoryNumber.model";
import _ from "lodash";
@Injectable()

/**
 * event service enables event CRUD
 * */
export class RecipecategoryService {

    //<editor-fold desc="PROPERTIES">
    public recipecategories: Recipecategory[];
    public selectedRecipecategory: Recipecategory;
    //</editor-fold>

    //<editor-fold desc="CONSTRUCTOR">
    /**
     * constructs a Location Service
     * @param _socketService {SocketService}
     * */
    constructor(private _socketService: SocketService,
                private _notificationService: NotificationService,
                private _dateService: DateService){

    }

    public getSortedRecipecategories(): Recipecategory[]{
        if (this.recipecategories){
            var sortedRecipecategories = this.recipecategories;
            sortedRecipecategories.sort(function(a, b) {
                return a.order - b.order;
            });
            return sortedRecipecategories;
        }else{
            return this.recipecategories;
        }
    }

    /**
     * loads all locations for current client
     * @returns {Observable<Location[]>}
     */
    public loadRecipecategories() : Observable<Recipecategory[]>{

        var instance = this;

        return new Observable<Recipecategory[]>(observer => {

            // load over web socket
            instance._socketService.socket.emit('recipecategory:load', {}, function(err, recipecategories){

                if(err){

                    // notify
                    //instance._notificationService.addNotification(new Notification('error_mbrs_loaded', Level.Error));
                    observer.error(err);
                }
                else{
                    //instance._dateService.parseDates(products);
                    instance.recipecategories = recipecategories;
                    observer.next(recipecategories);
                }
            });
        });
    }


    public createOrUpdateRecipecategory(recipecategory: Recipecategory)  : Observable<Recipecategory>{
        var instance = this;
        return new Observable<Recipecategory>(observer => {
            // load over web socket
            this._socketService.socket.emit('recipecategory:createOrUpdate',{recipecategory: recipecategory}, function(err, recipecategory){
                if(err){
                    observer.error(err);
                }
                else{
                    var index = _.findIndex(instance.recipecategories, {_id : recipecategory._id});
                    if (index >= 0){
                        instance.recipecategories[index] = recipecategory;

                    }else{
                        instance.recipecategories.push(recipecategory);
                    }
                    observer.next(recipecategory);
                }
            });
        });
    }

    public removeRecipecategory(recipecategory: Recipecategory)  : Observable<Recipecategory>{
        var instance = this;
        return new Observable<Recipecategory>(observer => {
            // load over web socket
            this._socketService.socket.emit('recipecategory:remove',{recipecategory: recipecategory}, function(err, recipecategory){
                if(err){
                    observer.error(err);
                }
                else{
                    var index = _.findIndex(instance.recipecategories, {_id : recipecategory._id});
                    if (index >= 0){
                        instance.recipecategories.splice(index,1);
                    }
                    observer.next(recipecategory);
                }
            });
        });
    }


/*
    public deleteSystem(system: System)  : Observable<System>{
        var instance = this;
        return new Observable<System>(observer => {
            // load over web socket
            this._socketService.socket.emit('system:delete',{systemId: system._id}, function(err, system){
                if(err){
                    observer.error(err);
                }
                else{
                    observer.next(system);
                }
            });
        });
    }*/
    //</editor-fold>



}