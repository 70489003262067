import {Component, ViewEncapsulation} from '@angular/core';
import {NotificationService} from "../../infrastructure/notification/notification.service";
import {Icon} from "../../infrastructure/icon/icon.directive";
import {Notification} from "../../infrastructure/notification/notification";

/**
 * decorator
 * */
@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'notifications',
    templateUrl: './notification.tmpl.html',
    styleUrls: ['./notification.sass']
})

/**
 * notification component
 * */
export class NotificationComponent{

    /**
     * constructs a notification component
     * */
    constructor(private _notificationService: NotificationService){}

    /**
     * gets all notifications
     * @returns {Notification[]}
     */
    public getNotifications() : Notification[]{

        // ask notification service for notifications
        return this._notificationService.notifications;
    }

    /**
     * removes a notification
     * @param notification
     */
    public removeNotification(notification: Notification){

        // ask notification
        return this._notificationService.removeNotification(notification);
    }
}
