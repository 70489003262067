import {Injectable} from '@angular/core';
import {SocketService} from "../socket/socket.service";
import {NotificationService} from "../notification/notification.service";
import {DateService} from "../date/date.service";
import {Category} from "../models/category.model";
import {User} from "../models/user.model";
import { Observable } from 'rxjs';
import _ from "lodash";

@Injectable()

/**
 * event service enables event CRUD
 * */
export class UserService {

    //<editor-fold desc="PROPERTIES">
    public users: User[];
    //</editor-fold>

    //<editor-fold desc="CONSTRUCTOR">
    /**
     * constructs a Location Service
     * @param _socketService {SocketService}
     * */
    constructor(private _socketService: SocketService,
                private _notificationService: NotificationService,
                private _dateService: DateService){

    }

    /**
     * loads all locations for current client
     * @returns {Observable<Location[]>}
     */
    public loadUsers() : Observable<User[]>{

        var instance = this;

        return new Observable<User[]>(observer => {

            // load over web socket
            instance._socketService.socket.emit('user:load', {}, function(err, users){

                if(err){

                    // notify
                    //instance._notificationService.addNotification(new Notification('error_mbrs_loaded', Level.Error));
                    observer.error(err);
                }
                else{
                    //instance._dateService.parseDates(products);
                    instance.users = users;
                    observer.next(users);
                }
            });
        });
    }

    public createOrUpdateUser(user: User)  : Observable<User>{
        var instance = this;
        return new Observable<User>(observer => {
            // load over web socket
            this._socketService.socket.emit('user:createOrUpdate',{user: user}, function(err, user){
                if(err){
                    observer.error(err);
                }
                else{
                    var index = _.findIndex(instance.users, {_id : user._id});
                    if (index >= 0){
                        instance.users[index] = user;

                    }else{
                        instance.users.push(user);
                    }
                    observer.next(user);
                }
            });
        });
    }

/*
    public deleteSystem(system: System)  : Observable<System>{
        var instance = this;
        return new Observable<System>(observer => {
            // load over web socket
            this._socketService.socket.emit('system:delete',{systemId: system._id}, function(err, system){
                if(err){
                    observer.error(err);
                }
                else{
                    observer.next(system);
                }
            });
        });
    }*/
    //</editor-fold>



}