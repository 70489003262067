import {Company} from "./company.model";
import {InventoryNumber} from "./inventoryNumber.model";
/**
 * User class
 * */
export class Category {

    // default
    public  _id: string;

    // info
    public name: string;
    public inv_nr: InventoryNumber;
    public company: Company;
    public order: number;

    constructor(){}
}


