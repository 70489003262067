import {Component, ViewEncapsulation} from '@angular/core';
import {User} from "../../infrastructure/models/user.model";
import {Headers, Http} from "@angular/http";
import 'rxjs/Rx';
import {Router} from "@angular/router";
import {SessionService} from "../../infrastructure/session/session.service";
import {SocketService} from "../../infrastructure/socket/socket.service";
import {LocalisationService} from "../../infrastructure/localisation/localisation.service";

import * as CryptoJS from "crypto-js";

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'login',
    templateUrl: './login.tmpl.html',
    styleUrls: ['./login.sass']
})

/**
 * login component
 * */
export class LoginComponent {

    /**
     * login user
     * @type {User}
     */
    public user: User = new User();


    /**
     * holds login success and error message
     * @type {{success: string, error: string}}
     */
    public loginMessage : any = {success: '', error: ''};

    /**
     * constructs an application component
     * */
    constructor(private _sessionService: SessionService,
                private _router: Router,
                private _socket: SocketService,
                private _localisationService: LocalisationService,
                private _http: Http){
    }

    /**
     * log in user
     * @param user
     */
    public login(user: User){

        // clear error message
        this.loginMessage.error = '';

        // ask session service to log in
        this._sessionService.login(user).subscribe(dbUser => {

            // todo: go back to page before login, use Location

            this._router.navigate(['products']);
            
        // login error
        }, err => {

            // parse error object
            var errorParsed = JSON.parse(err);

            // localise and display error
            this.loginMessage.error = this._localisationService.getText(errorParsed.code, []);
        });
    } 

    public ngOnInit(){

        // user exists, open patients
        if(this._sessionService.isLoggedIn()){
            this._router.navigate(['products']);
        }
    }
}