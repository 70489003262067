import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {Product} from "../models/product.model";
import {SocketService} from "../socket/socket.service";
import {NotificationService} from "../notification/notification.service";
import {Notification, Level} from "../notification/notification";
import {DateService} from "../date/date.service";
import {FilterService} from "../filter/filter.service";
import {Category} from "../models/category.model";
import {Recipe} from "../models/recipe.model";
import _ from "lodash";

import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import { Division } from '../models/division.model';


@Injectable()

/**
 * event service enables event CRUD
 * */
export class ProductService {

    //<editor-fold desc="PROPERTIES">
    public products: Product[];
    public productsAll: Product[];
    //</editor-fold>

    //<editor-fold desc="CONSTRUCTOR">
    /**
     * constructs a Location Service
     * @param _socketService {SocketService}
     * */
    constructor(private _socketService: SocketService,
                private _notificationService: NotificationService,
                private _dateService: DateService,
                private _filterService: FilterService){

    }

    /**
     * loads all locations for current client
     * @returns {Observable<Location[]>}
     */
    public loadProducts(state: any) : Observable<Product[]>{

        var instance = this;

        return new Observable<Product[]>(observer => {

            instance._socketService.socket.emit('products:load', {filter: instance._filterService.filterValue, category: instance._filterService.categoryid, limit: 40, skip: state.skip}, function(err, products){

                if(err){

                    // notify
                    //instance._notificationService.addNotification(new Notification('error_mbrs_loaded', Level.Error));
                    observer.error(err);
                }
                else{


                    if(state.loaded){

                        // callback loaded
                        state.loaded();
                    }

                    if(state.initial){

                        instance.products = products;
                    }
                    else{

                        instance.products = instance.products.concat(products);
                    }

                    observer.next(products);

                }
            });
        });
    }

    public loadAllProducts() : Observable<Product[]>{

        var instance = this;

        return new Observable<Product[]>(observer => {

            instance._socketService.socket.emit('products:load', {filter: null, category: null, limit: 999999}, function(err, products){

                if(err){

                    // notify
                    //instance._notificationService.addNotification(new Notification('error_mbrs_loaded', Level.Error));
                    observer.error(err);
                }
                else{
                    //instance._dateService.parseDates(products);
                    //instance.productsAll = products;
                    if (products){
                        instance.productsAll = [...products.sort((a, b) => a.product_name.localeCompare(b.product_name))];
                    }else{
                        instance.productsAll = products;
                    }
                    observer.next(instance.productsAll);
                }
            });
        });
    }

    public getProductsOfCategory(category: Category) :Product[]{
        var result : Product[] = [];
        for (var i = 0; i < this.productsAll.length; i++){
            if (this.productsAll[i].category._id == category._id){
                result.push(this.productsAll[i]);
            }
        }
        return result;
    }


    public getProductsOfDivision(division: Division) :Product[]{
        var result : Product[] = [];
        for (var i = 0; i < this.productsAll.length; i++){
            for (var j = 0; j < this.productsAll[i].divisions.length; i++){
                if (this.productsAll[i].divisions[j]._id == division._id){
                    result.push(this.productsAll[i]);
                    break;
                }
            }
        }
        return result;
    }

    public createOrUpdateProduct(product: Product)  : Observable<Product>{
        var instance = this;
        return new Observable<Product>(observer => {
            // load over web socket
            this._socketService.socket.emit('product:createOrUpdate',{product: product}, function(err, product){
                if(err){
                    observer.error(err);
                }
                else{
                    var found = false;
                    var index = _.findIndex(instance.productsAll, {_id : product._id});
                    if (index >= 0){
                        instance.productsAll[index] = product;

                    }else{
                        instance.productsAll.unshift(product);
                    }
                    observer.next(product);
                }
            });
        });
    }
/*
    public updateInventory(product: Product)  : Observable<Product>{
        var instance = this;
        return new Observable<Product>(observer => {
            this._socketService.socket.emit('product:updateInventory',{product: product}, function(err, product){
                if(err){
                    observer.error(err);
                }
                else{
                    var found = false;
                    var index = _.findIndex(instance.products, {_id : product._id});
                    if (index >= 0){
                        instance.products[index] = product;

                    }else{
                        instance.products.push(product);
                    }
                    observer.next(product);
                }
            });
        });
    }
*/
    public removeProduct(product: Product)  : Observable<Product>{
        var instance = this;
        return new Observable<Product>(observer => {
            this._socketService.socket.emit('product:remove',{product: product}, function(err, product){
                if(err){
                    instance._notificationService.addNotification(new Notification(err, Level.Error));
                    observer.error(err);
                }
                else{
                    var index = _.findIndex(instance.productsAll, {_id : product._id});
                    if (index >= 0){
                        instance.productsAll.splice(index,1);
                    }
                    observer.next(product);
                }
            });
        });
    }

/*
    public deleteSystem(system: System)  : Observable<System>{
        var instance = this;
        return new Observable<System>(observer => {
            // load over web socket
            this._socketService.socket.emit('system:delete',{systemId: system._id}, function(err, system){
                if(err){
                    observer.error(err);
                }
                else{
                    observer.next(system);
                }
            });
        });
    }*/
    //</editor-fold>




    public pricePerUnit(product: Product) : number{
        var price_per_unit: number = 0;

        if (product.price_unit == 'kg' || product.price_unit == 'l'){
            price_per_unit = product.price_per_unit * product.quantity;
        }else if (product.price_unit == 'u'){
            price_per_unit = product.price_per_unit;
        }else if (product.price_unit == 'box'){
            price_per_unit = product.price_per_unit / product.box;
        }
        return price_per_unit;
    }

    public pricePerKg(product: Product) : number {
        var price_per_kg_or_l = 0;

        if (product.price_unit == 'kg' || product.price_unit == 'l'){
            price_per_kg_or_l = product.price_per_unit;
        }else if (product.price_unit == 'u'){
            if (product.unit == 'kg' || product.unit == 'l') {
                price_per_kg_or_l = product.price_per_unit / product.quantity;
            }
        }else if (product.price_unit == 'box'){
            if (product.unit == 'kg' || product.unit == 'l') {
                price_per_kg_or_l = product.price_per_unit / product.quantity / product.box;
            }
        }
        return price_per_kg_or_l;
    }


    public pricePerBox(product: Product) : number {
        var price_per_box = 0;

        if (product.price_unit == 'kg' || product.price_unit == 'l'){
            price_per_box = product.price_per_unit * product.box * product.quantity;
        }else if (product.price_unit == 'u'){
            price_per_box = product.price_per_unit * product.box;
        }else if (product.price_unit == 'box'){
            price_per_box = product.price_per_unit ;
        }
        return price_per_box;
    }

    public productPrice(product: Product, quanity : number, unit : string) : number{
        var price_per_kg_or_l : number = this.pricePerKg(product);
        var price_per_unit : number =  this.pricePerUnit(product);
        var price_per_box : number =  this.pricePerBox(product);

        if (unit == 'kg' || unit == 'l'){
            return price_per_kg_or_l * quanity;
        }else if (unit == 'u'){
            return price_per_unit * quanity;
        }else if (unit == 'box'){
            return price_per_box * quanity;
        }
        return 0;
    }

    public recipePrice(recipe: Recipe, quanity : number, unit : string) : number{
        var totalPrice = 0;
        if (recipe.ingredients){
            for(var i=0; i<recipe.ingredients.length;i++){
                if (recipe.ingredients[i].product){
                    totalPrice+= this.productPrice(recipe.ingredients[i].product, recipe.ingredients[i].quantity, recipe.ingredients[i].unit);
                }else if(recipe.ingredients[i].recipe){
                    totalPrice+= this.recipePrice(recipe.ingredients[i].recipe, recipe.ingredients[i].quantity, recipe.ingredients[i].unit);
                }
            }
        }
        if (unit == "p"){
            return totalPrice * quanity / recipe.parts;
        }else{
            return totalPrice * quanity / recipe.quantity;
        }


    }


    public exportProducts(noFilter: Boolean) : Observable<string>{

        return new Observable<string>(observer => {

            // load over web socket
            this._socketService.socket.emit('product:export', {}, (err, url) =>{

                if(err){

                    // notify
                    //this._notificationService.addNotification(new Notification('error_export_duplicate', Level.Error));

                    // fire observer
                    observer.error(err);
                }
                else{

                    // notify
                   // this._notificationService.addNotification(new Notification('notification_export_duplicate', Level.Info));

                    // fire observer
                    observer.next(url);
                }
            });
        });
    }

    public exportProductsEmpty(noFilter: Boolean) : Observable<string>{

        return new Observable<string>(observer => {

            // load over web socket
            this._socketService.socket.emit('product:exportEmpty', {}, (err, url) =>{

                if(err){

                    // notify
                    //this._notificationService.addNotification(new Notification('error_export_duplicate', Level.Error));

                    // fire observer
                    observer.error(err);
                }
                else{

                    // notify
                    // this._notificationService.addNotification(new Notification('notification_export_duplicate', Level.Info));

                    // fire observer
                    observer.next(url);
                }
            });
        });
    }


    public getDataSource() : MyDataSource{

        return new MyDataSource(this._socketService, this._filterService);
    }


}


export class MyDataSource extends DataSource<Product | undefined> {
    private length = 3000;
    private pageSize = 100;
    private cachedData = Array.from<Product>({length: this.length});
    private fetchedPages = new Set<number>();
    private dataStream = new BehaviorSubject<(Product | undefined)[]>(this.cachedData);
    private subscription = new Subscription();
  
    constructor(private _socketService: SocketService,
        private _filterService: FilterService){
        super();
    }

    public ngOnInit(){
        var instance = this;
        this._socketService.socket.emit('products:count', {filter: this._filterService.filterValue, category: this._filterService.categoryid}, function(err, nbProducts){
            if(err){

            }
            else{
                instance.length = nbProducts;
                instance.cachedData = Array.from<Product>({length: this.length});
            }
        });

        setTimeout(time => {
            //this._mbrService.listen();
            //this._productService.loadProducts().subscribe();
        }, 200);

    }
    
   
    connect(collectionViewer: CollectionViewer): Observable<(Product | undefined)[]> {
      this.subscription.add(collectionViewer.viewChange.subscribe(range => {
        const startPage = this.getPageForIndex(range.start);
        const endPage = Math.min(10,this.getPageForIndex(range.end - 1));

        for (let i = startPage; i <= endPage; i++) {
          this.fetchPage(i);
        }
      }));
      return this.dataStream;
    }
  
    disconnect(): void {
      this.subscription.unsubscribe();
    }
  
    private getPageForIndex(index: number): number {
      return Math.floor(index / this.pageSize);
    }
  
    private fetchPage(page: number) {
      if (this.fetchedPages.has(page)) {
        return;
      }
      this.fetchedPages.add(page);
  
      var state = {};
      state['skip'] = page * this.pageSize;
      var instance = this;
      var skipProducts =  page * this.pageSize;
      this._socketService.socket.emit('products:load', {filter: instance._filterService.filterValue, category: instance._filterService.categoryid, limit: this.pageSize, skip: skipProducts}, function(err, products){
            if(err){

            }
            else{
                instance.cachedData.push(products);
            }
      });

    }
}