import {Component, ViewEncapsulation, Input, Output, EventEmitter, OnInit} from '@angular/core';
import 'rxjs/Rx';
import {CategoryService} from "../../../infrastructure/server/category.service";
import {Category} from "../../../infrastructure/models/category.model";
import {Recipecategory} from "../../../infrastructure/models/recipecategory.model";
import {SessionService} from "../../../infrastructure/session/session.service";
import {Router} from "@angular/router";
import {Recipe} from "../../../infrastructure/models/recipe.model";
import {RecipeService} from "../../../infrastructure/server/recipe.service";
import {ProductService} from "../../../infrastructure/server/product.service";
import {Product} from "../../../infrastructure/models/product.model";
import {Ingredient} from "../../../infrastructure/models/substructures.model";
import {RecipecategoryService} from "../../../infrastructure/server/recipecategory.service";
import _ from "lodash";
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';


export const MY_FORMATS = {
    parse: {
      dateInput: 'DD.MM.YYYY'
    },
    display: {
      dateInput: 'DD.MM.YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY'
    },
  };

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'offer-dialog',
    templateUrl: './offer-dialog.tmpl.html',
    styleUrls: ['./offer-dialog.sass'],
    providers: [
        // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
        // application's root module. We provide it at the component level here, due to limitations of
        // our example generation script.
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
        {provide: MAT_DATE_LOCALE, useValue: 'fr-CH'}
      ]
})

/**
 * patients component
 * */
export class OfferDialogComponent implements OnInit{
    @Input() recipe: Recipe;
    @Output() closeDialogAndSave:EventEmitter<any> = new EventEmitter();
    @Output() closeDialogAndDelete:EventEmitter<any> = new EventEmitter();
    @Output() closeDialog:EventEmitter<any> = new EventEmitter();
    public query = '';
    public filteredList = [];
    public queryRecipe = '';
    public filteredRecipeList = [];
    public filterCategoryId = "all";
    /**
     * constructs an application component
     * */
    constructor(
        private _categoryService: CategoryService,
        private _recipecategoryService: RecipecategoryService,
        private _sessionService: SessionService,
        private _recipeService: RecipeService,
        private _productService: ProductService,
        private _router: Router){
    }


    public ngOnInit(){
        setTimeout(time => {
            //this._mbrService.listen();
            //this._categoryService.loadCategories().subscribe();
            //this._recipeService.loadRecipes().subscribe();
            //this._productService.loadProducts().subscribe();
        }, 200);

    }

    public save(){
        this.closeDialogAndSave.emit(this.recipe);
    }

    public cancel(){
        this.closeDialog.emit(null);
    }

    public delete (){
        var r = confirm("Delete this Recipe?");
        if (r == true) {
            this.closeDialogAndDelete.emit(this.recipe);
        }

    }

    public getCategories() : Category[] {
        var possibleCategories = [];
        if (this.query !== "") {

            for(var i= 0; i < this._categoryService.categories.length; i++){
                for(var j= 0; j < this._productService.productsAll.length; j++){
                    if(this._categoryService.categories[i]._id.toString() == this.filterCategoryId || (this._productService.productsAll[j].product_name.toLowerCase().indexOf(this.query.toLowerCase()) > -1) && this._categoryService.categories[i]._id.toString() == this._productService.productsAll[j].category._id.toString()){
                        possibleCategories.push(this._categoryService.categories[i]);
                        break;
                    }
                }
            }
            return possibleCategories;
        }else{
            return this._categoryService.categories;
        }
    };

    public getRecipecategories() : Recipecategory[] {
        return this._recipecategoryService.recipecategories;
    };

    public getProducts() : Product[]{
        return this._productService.products;
    }

    public getRecipes() : Recipe[]{
        return this._recipeService.recipes;
    }


    public accent_fold (s: string): string {
        var accentMap = {'á':'a','â':'a', 'é':'e','è':'e','ê':'e','ç':'c','œ':'oe', 'í':'i','ó':'o','ú':'u','ä':'a','ö':'o','ü':'u','à':'a','î':'i','ï':'i','ô':'o','û':'u'};
        if (!s) { return ''; }
        var ret = '';
        for (var i = 0; i < s.length; i++) {
            ret += accentMap[s.charAt(i)] || s.charAt(i);
        }
        return ret;
    };

    filter(event: any) {
        if (this.query !== "") {
            this.filteredList = this._productService.productsAll.filter(function (el) {
                return (this.accent_fold(el.product_name.toLowerCase()).indexOf(this.accent_fold(this.query.toLowerCase())) > -1) && (this.filterCategoryId == "all" || this.filterCategoryId == "product" || el.category._id.toString() == this.filterCategoryId);
            }.bind(this));
            this.filteredRecipeList = this._recipeService.recipesAll.filter(function (el) {
                var result = false;
                if ((this.accent_fold(el.name.toLowerCase()).indexOf(this.accent_fold(this.query.toLowerCase())) > -1) && el._id != this.recipe._id && (this.filterCategoryId == "all" || this.filterCategoryId == "recipe" )){
                    result = true;
                    for(var i= 0; i < el.ingredients.length; i++){
                        if(el.ingredients[i].recipe && el.ingredients[i].recipe._id == this.recipe._id){
                            result = false;
                        }
                    }
                }
                return result;
            }.bind(this));
        } else {
            this.filteredList = [];
            this.filteredRecipeList = [];
        }
    }

    addComment(){
        var ingredient: Ingredient = new Ingredient();
        ingredient.order = this.recipe.ingredients.length;
        ingredient.text = "";
        this.recipe.ingredients.push(ingredient);
    }

    select(item) {
        var ingredient: Ingredient = new Ingredient();
        if (item.product_name){
            ingredient.product = item;
        }else{
            ingredient.recipe = item;
        }
        ingredient.quantity = 1;
        ingredient.unit = item.unit;
        ingredient.order = this.recipe.ingredients.length;

        this.recipe.ingredients.push(ingredient);
        this.query = "";
        this.filteredList = [];
        this.filteredRecipeList = [];
    }

    removeIngredient(ingredient){
        var index = this.recipe.ingredients.indexOf(ingredient);
        if (index >= 0){
            this.recipe.ingredients.splice(index,1);
        }
    }

    getIngredientName(ingredient){
        if(ingredient.product){
            return ingredient.product.product_name;
        }else{
            return ingredient.recipe.name;
        }
    }

    getIngredientDetail(ingredient){
        if(ingredient.product){
            if (ingredient.product.sup_name){
                return ingredient.product.sup_name + " ("+ingredient.product.quantity+ingredient.product.unit+")";
            }else{
                return "("+ingredient.product.quantity+ingredient.product.unit+")";
            }
        }else{
            return "";
        }
    }

    getIngredientPrice(ingredient){
        if(ingredient.product){
            return this._productService.productPrice(ingredient.product,ingredient.quantity,ingredient.unit);
        }else if(ingredient.recipe){
            return this._productService.recipePrice(ingredient.recipe,ingredient.quantity,ingredient.unit);
        }
        return 0;
    }

    public getIngredients(): Ingredient[]{
        if (this.recipe.ingredients){
            var sortedIngredients = this.recipe.ingredients;
            sortedIngredients.sort(function(a, b) {
                return a.order - b.order;
            });
            return sortedIngredients;
        }else{
            return this.recipe.ingredients;
        }
    }

    public  getRecipeTotalPrice(): number{
        return this._productService.recipePrice(this.recipe, this.recipe.quantity, this.recipe.unit);
    }

    public getDetailedInfo(product){
        if (product.sup_name){
            return "  ("+product.sup_name+", "+product.quantity+product.unit+")";
        }else{
            return "  ("+product.quantity+product.unit+")";
        }
    }

    public removeText(){
        if (this.recipe.name == "new offer"){
            this.recipe.name = "";
        }
    }

    public canEdit(){
        if (this.recipe.protected){
            return this._sessionService.user.recipeWriteAdmin;
        }else{
            return (this._sessionService.user.recipeWriteAdmin || this._sessionService.user.recipeWrite);
        }
    }

    public canEditProtected(){
        return this._sessionService.user.recipeWriteAdmin;
    }
}