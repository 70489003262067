import {Component, ViewEncapsulation, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {SocketService} from "../../infrastructure/socket/socket.service";
import {SessionService} from "../../infrastructure/session/session.service";
import {NavigationComponent} from "../navigation/navigation.component";
import {LocalisationPipe} from "../../infrastructure/pipes/localisation.pipe";
import {ProductService} from "../../infrastructure/server/product.service";
import {Product} from "../../infrastructure/models/product.model";
import {CategoryService} from "../../infrastructure/server/category.service";
import {Category} from "../../infrastructure/models/category.model";
import {Inventory} from "../../infrastructure/models/inventory.model";
import {User} from "../../infrastructure/models/user.model";
import {InventoryListService} from "../../infrastructure/server/inventoryList.service";
import {InventoryListDialogComponent} from "./dialog/inventoryList-dialog.component";
import {FilterService} from "../../infrastructure/filter/filter.service";
import {InventoryProduct} from "../../infrastructure/models/inventoryProduct.model";
import {UserService} from "../../infrastructure/server/user.service";

import _ from "lodash";
import * as jsPDF from "jspdf";

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'inventoryList',
    templateUrl: './inventoryList.tmpl.html',
    styleUrls: ['./inventoryList.sass']
})

/**
 * products component
 * */
export class InventoryListComponent implements OnInit {
    private _showDialog : boolean = false;

    private _selectedInventory: Inventory = new Inventory();
    private _userList : User[] = [];
    /**
     * constructs an application component
     * */
    constructor(private _socket: SocketService,
                private _inventoryListService: InventoryListService,
                private _sessionService: SessionService,
                private _userService: UserService,
                private _filterService: FilterService,
                private _productService: ProductService,
                private _router: Router){}

    public ngOnInit(){

        if(this._sessionService.isLoggedIn()) {
            // check if cookie set
            this._sessionService.user = this._sessionService.getUser();
            // create a socket connection
            this._sessionService.socketConnect(this._sessionService.user );
            if (!(this._sessionService.user.inventoryRead || this._sessionService.user.inventoryWrite || this._sessionService.user.superAdmin)){
                this._router.navigate(['login']);
            }
            setTimeout(time => {
                //this._mbrService.listen();
                this._inventoryListService.loadInventories().subscribe();
                this._userService.loadUsers().subscribe();
            }, 100);
        }else{
            // redirect to login
            setTimeout(time => {
                this._router.navigate(['login']);
            }, 100);
        }
    }

    /**
     *
     * @returns {Mbr[]}
     */
    public getInventoryList() : Inventory[] {
        return this._inventoryListService.inventories;
    };


    public createOrUpdateInventory(inventory: Inventory){
        this._inventoryListService.createOrUpdateInventory(inventory).subscribe();
    }

    public openDialog(inventory: Inventory){
        if (inventory == null){
            if (this._selectedInventory){
                delete this._selectedInventory._id;
            }
            this._selectedInventory.name = "";
            this._selectedInventory.users = [];
        }else{
            this._selectedInventory = _.cloneDeep(inventory);
        }
        this._userList = this._userService.users;
        this._showDialog = true;
    }

    public addOrUpdateInventory(inventory: Inventory){
        this._showDialog = false;
        this._inventoryListService.createOrUpdateInventory(inventory).subscribe();
    }

    public deleteInventory(inventory: Inventory){
        this._showDialog = false;
        this._inventoryListService.removeInventory(inventory).subscribe();
    }

    public openInventoryDetail(inventory : Inventory){
        var allowed = false;
        var instance = this;
        inventory.users.forEach(function (inventoryUser) {
            if (inventoryUser.userId as any == instance._sessionService.user._id){
                allowed = true;
            }
        });

        if(this._sessionService.user.superAdmin || allowed && (this._sessionService.user.inventoryRead || this._sessionService.user.inventoryWrite)){
            // clear selected mbr
            this._inventoryListService.inventoryProducts = [];

            // navigate to detail panel
            this._router.navigate(['inventoryProducts', inventory._id]);
        }
    }

    public isUserAllowed(){
        return this._sessionService.user.superAdmin;
    }

    public export(){
        this._productService.exportProducts(false).subscribe(url => {
            window.open(url, '_self');
            //use url to start download
        }, err => {});
    }

    public exportEmpty(){
        this._productService.exportProductsEmpty(false).subscribe(url => {
            window.open(url, '_self');
            //use url to start download
        }, err => {});
    }
}
