import {Component, ViewEncapsulation, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {SocketService} from "../../infrastructure/socket/socket.service";
import {SessionService} from "../../infrastructure/session/session.service";
import {LocalisationPipe} from "../../infrastructure/pipes/localisation.pipe";
import {ProductService} from "../../infrastructure/server/product.service";
import {Product} from "../../infrastructure/models/product.model";
import {CategoryService} from "../../infrastructure/server/category.service";
import {InventoryNumberDialogComponent} from "./dialog/inventoryNumber-dialog.component";
import {InventoryNumber} from "../../infrastructure/models/inventoryNumber.model";

import _ from "lodash";

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'inventoryNumbers',
    templateUrl: './inventoryNumbers.tmpl.html',
    styleUrls: ['./inventoryNumbers.sass']
})

/**
 * products component
 * */
export class InventoryNumbersComponent implements OnInit{
    private _showDialog : boolean = false;

    private _selectedInventoryNumber: InventoryNumber = new InventoryNumber();

    /**
     * constructs an application component
     * */
    constructor(private _socket: SocketService,
                private _productService: ProductService,
                private _sessionService: SessionService,
                private _categoryService: CategoryService,
                private _router: Router){}


    public ngOnInit(){
        if(this._sessionService.isLoggedIn()) {
            // check if cookie set
            this._sessionService.user = this._sessionService.getUser();
            // create a socket connection
            this._sessionService.socketConnect(this._sessionService.user );
            if (!(this._sessionService.user.superAdmin)){
                this._router.navigate(['login']);
            }
            setTimeout(time => {
                //this._mbrService.listen();
                this._categoryService.loadInventoryNumbers().subscribe();
            }, 100);
        }else{
            // redirect to login
            setTimeout(time => {
                this._router.navigate(['login']);
            }, 100);
        }
    }

    /**
     *
     * @returns {Mbr[]}
     */
    public getInventoryNumbers() : InventoryNumber[] {
        return this._categoryService.inventoryNumbers;
    };


    public openDialog(inventoryNumber: InventoryNumber){
        if (inventoryNumber == null){
            if (this._selectedInventoryNumber){
                this._selectedInventoryNumber = new InventoryNumber();
            }
            this._selectedInventoryNumber.name = "";
            this._selectedInventoryNumber.inv_nr = "";
            this._selectedInventoryNumber.sort = 99;
        }else{
            this._selectedInventoryNumber = _.cloneDeep(inventoryNumber);
        }
        this._showDialog = true;
    }

    public addOrUpdateInventoryNumber(inventoryNumber: InventoryNumber){
        this._showDialog = false;
        this._categoryService.createOrUpdateInventoryNumber(inventoryNumber).subscribe();
    }

    public deleteInventoryNumber(inventoryNumber: InventoryNumber){
        this._showDialog = false;
        this._categoryService.removeInventoryNumber(inventoryNumber).subscribe();
    }
}