import {Component, ViewEncapsulation} from '@angular/core';
import {SessionService} from "./infrastructure/session/session.service";

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.sass']
})

/**
 * the base application component, enables routing
 * */
export class AppComponent{

    /**
     * constructs an application component
     * */
    constructor(private _sessionService: SessionService){}

    /**
     * checks if a user is logged in
     * @returns {boolean}
     */
    public isLoggedIn(){
        return this._sessionService.isLoggedIn();
    }
}