import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {SocketService} from "../socket/socket.service";
import {NotificationService} from "../notification/notification.service";
import {DateService} from "../date/date.service";
import {Category} from "../models/category.model";
import {InventoryNumber} from "../models/inventoryNumber.model";
import _ from "lodash";
@Injectable()

/**
 * event service enables event CRUD
 * */
export class CategoryService {

    //<editor-fold desc="PROPERTIES">
    public categories: Category[];
    public inventoryNumbers: InventoryNumber[];
    public selectedCategory: Category;
    //</editor-fold>

    //<editor-fold desc="CONSTRUCTOR">
    /**
     * constructs a Location Service
     * @param _socketService {SocketService}
     * */
    constructor(private _socketService: SocketService,
                private _notificationService: NotificationService,
                private _dateService: DateService){

    }

    public getSortedCategories(): Category[]{
        if (this.categories){
            var sortedCategories = this.categories;
            sortedCategories.sort(function(a, b) {
                return a.order - b.order;
            });
            return sortedCategories;
        }else{
            return this.categories;
        }
    }

    /**
     * loads all locations for current client
     * @returns {Observable<Location[]>}
     */
    public loadCategories() : Observable<Category[]>{

        var instance = this;

        return new Observable<Category[]>(observer => {

            // load over web socket
            instance._socketService.socket.emit('category:load', {}, function(err, categories){

                if(err){

                    // notify
                    //instance._notificationService.addNotification(new Notification('error_mbrs_loaded', Level.Error));
                    observer.error(err);
                }
                else{
                    //instance._dateService.parseDates(products);
                    instance.categories = categories;
                    observer.next(categories);
                }
            });
        });
    }


    public createOrUpdateCategory(category: Category)  : Observable<Category>{
        var instance = this;
        return new Observable<Category>(observer => {
            // load over web socket
            this._socketService.socket.emit('category:createOrUpdate',{category: category}, function(err, category){
                if(err){
                    observer.error(err);
                }
                else{
                    var index = _.findIndex(instance.categories, {_id : category._id});
                    if (index >= 0){
                        instance.categories[index] = category;

                    }else{
                        instance.categories.push(category);
                    }
                    observer.next(category);
                }
            });
        });
    }

    public removeCategory(category: Category)  : Observable<Category>{
        var instance = this;
        return new Observable<Category>(observer => {
            // load over web socket
            this._socketService.socket.emit('category:remove',{category: category}, function(err, category){
                if(err){
                    observer.error(err);
                }
                else{
                    var index = _.findIndex(instance.categories, {_id : category._id});
                    if (index >= 0){
                        instance.categories.splice(index,1);
                    }
                    observer.next(category);
                }
            });
        });
    }

    public loadInventoryNumbers() : Observable<InventoryNumber[]>{

        var instance = this;

        return new Observable<InventoryNumber[]>(observer => {

            // load over web socket
            instance._socketService.socket.emit('inventoryNumber:load', {}, function(err, inventoryNumbers){

                if(err){

                    // notify
                    //instance._notificationService.addNotification(new Notification('error_mbrs_loaded', Level.Error));
                    observer.error(err);
                }
                else{
                    //instance._dateService.parseDates(products);
                    instance.inventoryNumbers = inventoryNumbers;
                    observer.next(inventoryNumbers);
                }
            });
        });
    }

    public createOrUpdateInventoryNumber(inventoryNumber: InventoryNumber)  : Observable<InventoryNumber>{
        var instance = this;
        return new Observable<InventoryNumber>(observer => {
            // load over web socket
            this._socketService.socket.emit('inventoryNumber:createOrUpdate',{inventoryNumber: inventoryNumber}, function(err, inventoryNumber){
                if(err){
                    observer.error(err);
                }
                else{
                    var index = _.findIndex(instance.inventoryNumbers, {_id : inventoryNumber._id});
                    if (index >= 0){
                        instance.inventoryNumbers[index] = inventoryNumber;

                    }else{
                        instance.inventoryNumbers.push(inventoryNumber);
                    }
                    observer.next(inventoryNumber);
                }
            });
        });
    }

    public removeInventoryNumber(inventoryNumber: InventoryNumber)  : Observable<InventoryNumber>{
        var instance = this;
        return new Observable<InventoryNumber>(observer => {
            // load over web socket
            this._socketService.socket.emit('inventoryNumber:remove',{inventoryNumber: inventoryNumber}, function(err, inventoryNumber){
                if(err){
                    observer.error(err);
                }
                else{
                    var index = _.findIndex(instance.inventoryNumbers, {_id : inventoryNumber._id});
                    if (index >= 0){
                        instance.inventoryNumbers.splice(index,1);
                    }
                    observer.next(inventoryNumber);
                }
            });
        });
    }
/*
    public deleteSystem(system: System)  : Observable<System>{
        var instance = this;
        return new Observable<System>(observer => {
            // load over web socket
            this._socketService.socket.emit('system:delete',{systemId: system._id}, function(err, system){
                if(err){
                    observer.error(err);
                }
                else{
                    observer.next(system);
                }
            });
        });
    }*/
    //</editor-fold>



}