import {Injectable} from '@angular/core';
import {Category} from "../models/category.model";
import {Observable, Subject} from "rxjs";

@Injectable()

/**
 * Filter service
 * */
export class FilterService {

    public filterValue: string;
    public filter = new Subject<string>();
    public categoryid: string;
    public category = new Subject<string>();
    public limit: number;

    public filterValueR: string;
    public recipecategoryid: string;

    public filterValueO: string;
    
    /**
     * constructs the filter service
     * */
    constructor(){

        // define base url
        this.filterValue = '' ;
        this.categoryid = '';
        this.limit = 40;
        this.filterValueR = '';
        this.filterValueO = '';
        this.recipecategoryid = '57fb2fc159e946000334359b';
    }


    public setFilter(filter : string) {
         this.filter.next(filter);
    }

    public setCategory(category : string) {
        this.category.next(category);
   }
}