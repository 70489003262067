import {Component, ViewEncapsulation, Input, Output, EventEmitter, OnInit} from '@angular/core';
import 'rxjs/Rx';
import {Product} from "../../../infrastructure/models/product.model";
import {CategoryService} from "../../../infrastructure/server/category.service";
import {Category} from "../../../infrastructure/models/category.model";
import {SessionService} from "../../../infrastructure/session/session.service";
import {Router} from "@angular/router";
import {ProductService} from "../../../infrastructure/server/product.service";
import { DivisionService } from 'src/app/infrastructure/server/division.service';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'product-dialog',
    templateUrl: './product-dialog.tmpl.html',
    styleUrls: ['./product-dialog.sass']
})
 
/**
 * patients component
 * */
export class ProductDialogComponent implements OnInit{

    @Input() product: Product;
    @Output() closeDialogAndSave:EventEmitter<any> = new EventEmitter();
    @Output() closeDialogAndDelete:EventEmitter<any> = new EventEmitter();
    @Output() closeDialog:EventEmitter<any> = new EventEmitter();

    public checkboxList: boolean[] = [];
    /**
     * constructs an application component
     * */
    constructor(
        private _categoryService: CategoryService,
        private _sessionService: SessionService,
        private _productService: ProductService,
        private _divisionService: DivisionService,
        private _router: Router){}


    public ngOnInit(){
        setTimeout(time => {
            //this._mbrService.listen();
            this._categoryService.loadCategories().subscribe();
            this._divisionService.loadDivisions().subscribe(division => {
                this.calcCheckedList();
            });
        }, 100);
    }

    public save(){
        this.product.divisions = [];
        this._divisionService.getDivisions().forEach(division => {
            if(this.checkboxList[division.name]){
                this.product.divisions.push(division);
            }
        });
        this.closeDialogAndSave.emit(this.product);
    }

    public cancel(){
        this.closeDialog.emit(null);
    }

    public delete (){
        var r = confirm("Delete this Product?");
        if (r == true) {
            this.closeDialogAndDelete.emit(this.product);
        }
    }
    
    public getCategories() : Category[] {
        return this._categoryService.categories;
    };

    public getNumber(num){
        var array:number[] =[];
        for (var _i = 0; _i < num; _i++) {
            array.push(_i);
        }
        return array;
    }

    public getPricePerBox(){
        return this._productService.pricePerBox(this.product);
    }

    public getPricePerKg(){
        return this._productService.pricePerKg(this.product);
    }

    public getPricePerUnit(){
        return this._productService.pricePerUnit(this.product);
    }

    public canEdit(){
        return this._sessionService.user.productWrite;
    }

    public getDivisions(){
        return this._divisionService.getDivisions();
    }

    public calcCheckedList(){
        if(this._divisionService.getDivisions() && this._divisionService.getDivisions().length > 0){
            this._divisionService.getDivisions().forEach(division => {
                this.checkboxList[division.name] = false;
                if(this.product.divisions){
                    this.product.divisions.forEach(checkedDivision =>  {
                        if (division.name == checkedDivision.name){
                            this.checkboxList[division.name] = true;
                        }
                    });

                }
            });
        }
    }
}