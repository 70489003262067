import {NgModule} from '@angular/core';
import {LocationStrategy, HashLocationStrategy} from "@angular/common";
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {AppComponent} from './app.component';
import {appRoutingProviders, routing} from "./app.routes";
import {NotificationComponent} from "./partials/notification/notification.component";
import {NavigationComponent} from "./partials/navigation/navigation.component";
import {CategoryDialogComponent} from "./partials/categories/dialog/category-dialog.component";
import {CategoryDrag} from "./infrastructure/dragDrop/category-drag.directive";
import {LocalisationPipe} from "./infrastructure/pipes/localisation.pipe";
import {InventoryListDialogComponent} from "./partials/inventoryList/dialog/inventoryList-dialog.component";
import {InventoryNumberDialogComponent} from "./partials/inventoryNumbers/dialog/inventoryNumber-dialog.component";
import {Icon} from "./infrastructure/icon/icon.directive";
import {ProductDialogComponent} from "./partials/products/dialog/product-dialog.component";
import {UserDialogComponent} from "./partials/users/dialog/user-dialog.component";
import {LoginComponent} from "./partials/login/login.component";
import {ProductsComponent} from "./partials/products/products.component";
import {RecipesComponent} from "./partials/recipes/recipes.component";
import {OffersComponent} from "./partials/offers/offers.component";
import {CategoriesComponent} from "./partials/categories/categories.component";
import {InventoryComponent} from "./partials/inventory/inventory.component";
import {InventoryListComponent} from "./partials/inventoryList/inventoryList.component";
import {RecipeDialogComponent} from "./partials/recipes/dialog/recipe-dialog.component";
import {OfferDialogComponent} from "./partials/offers/dialog/offer-dialog.component";
import {UsersComponent} from "./partials/users/users.component";
import {InventoryNumbersComponent} from "./partials/inventoryNumbers/inventoryNumbers.component";
import {SocketService} from "./infrastructure/socket/socket.service";
import {SessionService} from "./infrastructure/session/session.service";
import {CategoryService} from "./infrastructure/server/category.service";
import {CookieService} from "./infrastructure/cookies/cookie.service";
import {ConfigService} from "./infrastructure/config/confg.service";
import {LocalisationService} from "./infrastructure/localisation/localisation.service";
import {NotificationService} from "./infrastructure/notification/notification.service";
import {DateService} from "./infrastructure/date/date.service";
import {FilterService} from "./infrastructure/filter/filter.service";
import {ProductService} from "./infrastructure/server/product.service";
import {RecipeService} from "./infrastructure/server/recipe.service";
import {InventoryListService} from "./infrastructure/server/inventoryList.service";
import {UserService} from "./infrastructure/server/user.service";
import {HttpModule} from "@angular/http";
import {IngredientDrag} from "./infrastructure/dragDrop/ingredient-drag.directive";
import {RecipePrintComponent} from "./partials/recipes/print/print-dialog.component";
import {OfferPrintComponent} from "./partials/offers/print/print-dialog-offer.component";
import {RecipecategoriesComponent} from "./partials/recipecategories/recipecategories.component";
import {RecipecategoryDialogComponent} from "./partials/recipecategories/dialog/recipecategory-dialog.component";
import {RecipecategoryDrag} from "./infrastructure/dragDrop/recipecategory-drag.directive";
import {RecipecategoryService} from "./infrastructure/server/recipecategory.service";
import {SpinnerComponent} from "./infrastructure/spinner/spinner.component";
import {LazyLoader} from "./infrastructure/lazyLoader/lazy-loader.directive";
import { DateFormatPipe } from './infrastructure/pipes/dateFormat.pipe';
import {MatTableModule} from '@angular/material/table';
import {ScrollDispatchModule} from '@angular/cdk/scrolling';
import {MatButtonModule, MatNativeDateModule,MatCheckboxModule } from '@angular/material';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DivisionService } from './infrastructure/server/division.service';
import {DivisionDialogComponent} from "./partials/divisions/dialog/division-dialog.component";
import { DivisionsComponent } from './partials/divisions/divisions.component';
import {MatSelectModule} from '@angular/material/select';


@NgModule({
    declarations:
        [
            AppComponent,
            NotificationComponent,
            NavigationComponent,
            CategoryDialogComponent,
            CategoryDrag,
            IngredientDrag,
            LocalisationPipe,
            InventoryListDialogComponent,
            Icon,
            InventoryNumberDialogComponent,
            ProductDialogComponent,
            UserDialogComponent,
            LoginComponent,
            ProductsComponent,
            RecipesComponent,
            OffersComponent,
            CategoriesComponent,
            InventoryComponent,
            InventoryListComponent,
            InventoryNumbersComponent,
            ProductsComponent,
            RecipeDialogComponent,
            UsersComponent,
            RecipePrintComponent,
            OfferPrintComponent,
            OfferDialogComponent,
            RecipecategoriesComponent,
            RecipecategoryDialogComponent,
            SpinnerComponent,
            LazyLoader,
            RecipecategoryDrag,
            DateFormatPipe,
            DivisionDialogComponent,
            DivisionsComponent
        ],
    imports:
        [
            BrowserModule,
            RouterModule,
            FormsModule,
            HttpModule,
            routing,
            MatTableModule,
            ScrollDispatchModule,
            MatButtonModule,
            MatDatepickerModule,
            MatFormFieldModule,
            MatNativeDateModule,
            MatCheckboxModule,
            MatSelectModule,
            BrowserAnimationsModule
            
        ],
    entryComponents: [SpinnerComponent],
    bootstrap: [AppComponent],
    providers:
        [
            appRoutingProviders,
            {provide: LocationStrategy, useClass: HashLocationStrategy},
            SocketService,
            SessionService,
            CategoryService,
            CookieService,
            ConfigService,
            LocalisationService,
            NotificationService,
            DateService,
            FilterService,
            ProductService,
            RecipeService,
            InventoryListService,
            UserService,
            RecipecategoryService,
            MatDatepickerModule,
            DivisionService
        ]
})

export class AppModule {}

