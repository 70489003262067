import {Routes, RouterModule} from "@angular/router";
import {ModuleWithProviders} from "@angular/core";
import {LoginComponent} from "./partials/login/login.component";
import {ProductsComponent} from "./partials/products/products.component";
import {RecipesComponent} from "./partials/recipes/recipes.component";
import {OffersComponent} from "./partials/offers/offers.component";
import {InventoryListComponent} from "./partials/inventoryList/inventoryList.component";
import {InventoryComponent} from "./partials/inventory/inventory.component";
import {CategoriesComponent} from "./partials/categories/categories.component";
import {RecipecategoriesComponent} from "./partials/recipecategories/recipecategories.component";
import {InventoryNumbersComponent} from "./partials/inventoryNumbers/inventoryNumbers.component";
import {UsersComponent} from "./partials/users/users.component";
import { DivisionsComponent } from "./partials/divisions/divisions.component";

/**
 * define app routes
 * */
const appRoutes: Routes = [
    {path:'', component: LoginComponent},
    {path:'login', component: LoginComponent},
    {path:'products', component: ProductsComponent},
    {path:'recipes', component: RecipesComponent},
    {path:'offers', component: OffersComponent},
    {path:'inventory', component: InventoryListComponent},
    {path:'inventoryProducts/:id', component: InventoryComponent},
    {path:'categories', component: CategoriesComponent},
    {path:'divisions', component: DivisionsComponent},
    {path:'recipecategories', component: RecipecategoriesComponent},
    {path:'invNumbers', component: InventoryNumbersComponent},
    {path:'users', component: UsersComponent}
];

// export routes
export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);