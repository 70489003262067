import {Component, Input, Injector, ViewEncapsulation} from '@angular/core';
import {Location} from '@angular/common';
import {Router, RouterLink} from "@angular/router";
import {SessionService} from "../../infrastructure/session/session.service";
import {Http} from "@angular/http";
import {LocalisationService} from "../../infrastructure/localisation/localisation.service";
import {FilterService} from "../../infrastructure/filter/filter.service";
import {ProductService} from "../../infrastructure/server/product.service";
import {Category} from "../../infrastructure/models/category.model";
import {Recipecategory} from "../../infrastructure/models/recipecategory.model";
import {CategoryService} from "../../infrastructure/server/category.service";
import {RecipeService} from "../../infrastructure/server/recipe.service";
import {RecipecategoryService} from "../../infrastructure/server/recipecategory.service";

/**
 * decorator
 * */
@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'navigation',
    templateUrl: './navigation.tmpl.html',
    styleUrls: ['./navigation.sass']
})

/**
 * Gallery component
 * */
export class NavigationComponent{

    /**
     * constructs a gallery instance
     * */
    constructor(private _sessionService: SessionService,
                private _router: Router,
                private _location: Location,
                private _localisationService: LocalisationService,
                private _productService: ProductService,
                private _filterService: FilterService,
                private _categoryService: CategoryService,
                private _recipeService: RecipeService,
                private _recipecategoryService: RecipecategoryService,
                private _http: Http){
    }

    public ngOnInit(){
        if(this._sessionService.isLoggedIn()) {
            // check if cookie set
            this._sessionService.user = this._sessionService.getUser();
        }
        setTimeout(time => {
            //this._mbrService.listen();
            this._categoryService.loadCategories().subscribe();
            this._recipecategoryService.loadRecipecategories().subscribe();
        }, 300);
    }

    public openPatients(){
        this._router.navigate(['Patients']);
    }

    /**
     * logs out the user
     */
    public logout(){
        this._sessionService.logout();
    }

    /**
     * resets the filter
     */
    public resetFilter(){
        this._filterService.filterValue = '';
        this._filterService.setFilter(this._filterService.filterValue);
        //this.reloadData();
    }

    public resetFilterR(){
        this._filterService.filterValueR = '';
        this.reloadDataRecipe();
    }

    /**
     * returns all available languages
     * @returns {string[]}
     */
    public getLanguages() : string[]{
        return this._localisationService.languages;
    }

    public reloadData(){
        this._filterService.setFilter(this._filterService.filterValue);
        this._filterService.setCategory(this._filterService.categoryid);
       // this._productService.loadProducts({initial: true, skip: 0}).subscribe();
    }

    public reloadDataRecipe(){
        this._recipeService.loadRecipes().subscribe();
    }

    public getCategories() : Category[] {
        return this._categoryService.categories;
    };

    public getRecipecategories() : Recipecategory[] {
        return this._recipecategoryService.recipecategories;
    };

    public showUser(){
        return this._sessionService.user.superAdmin;
    }

    public showCategory(){
        return this._sessionService.user.superAdmin;
    }

    public showSettings(){
        return this._sessionService.user.superAdmin;
    }

    public showInventory(){
        return this._sessionService.user.inventoryRead || this._sessionService.user.inventoryWrite || this._sessionService.user.superAdmin;
    }

    public showProduct(){
        return this._sessionService.user.productRead || this._sessionService.user.productWrite || this._sessionService.user.superAdmin;
    }

    public showRecipe(){
        //return false;
        return this._sessionService.user.recipeRead || this._sessionService.user.recipeWrite || this._sessionService.user.recipeWriteAdmin || this._sessionService.user.superAdmin;
    }

    public showOffer(){
        //return false;
        return this._sessionService.user.recipeRead || this._sessionService.user.recipeWrite || this._sessionService.user.recipeWriteAdmin || this._sessionService.user.superAdmin;
    }

    public isRouteActive(routeToCheck: string) : boolean {

        var route = this.getRoute();

        if(route){
            return route.indexOf(routeToCheck) != -1;
        }
        else if(routeToCheck == '/products'){
            return true;
        }

        return false;
    }

    public getRoute() : string{

        var path = this._location.path();

        if(path){
            var paths = path.split('/');
            return paths[1];
        }

        return undefined;
    }

    public navigateTo(site : string){
        this._router.navigate([site]);
    }

    public isPageOffers() : boolean{
        var route = this.getRoute();
        return (route == 'offers');
    }

    public isPageRecipes() : boolean{
        var route = this.getRoute();
        return (route == 'recipes');
    }

    public isPageProducts() : boolean{
        var route = this.getRoute();
        return (route == 'products' || route == 'inventoryProducts');
    }
}
