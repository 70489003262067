import {Company} from "./company.model";
/**
 * User class
 * */
export class User {

    // default
    public  _id: string;

    // info
    public name: string;
    public password: string;
    public token: string;
    public company: Company;
    
    public superAdmin: boolean;
    public recipeWriteAdmin: boolean;
    public recipeWrite: boolean;
    public recipeRead: boolean;
    public productWrite: boolean;
    public productRead: boolean;
    public inventoryWrite: boolean;
    public inventoryRead: boolean;
    
    constructor(){}
}


