import {Injectable} from '@angular/core';

@Injectable()

/**
 * configuration service
 * */
export class ConfigService {

    /** application base URL */
    public BASE_URL: string;

    /**
     * constructs the configuration service
     * */
    constructor() {
        this.BASE_URL = window.location.origin;
    }
}
