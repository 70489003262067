import {Directive, ElementRef, Input, OnChanges} from '@angular/core';
import {Http} from "@angular/http";

@Directive({
    selector: '[icon]'
})

/**
 * Icon directive
 * */
export class Icon implements OnChanges {

    @Input() name: string;

    /**
     * constructs an icon directive
     * @param _element {ElementRef} holds the element the directive was called upon
     * @param _http {Http} service
     * */
    constructor(private _element: ElementRef,
                private _http:Http){}

    //<editor-fold desc="METHODS">
    /**
     * loads the icon specified by the name property
     * */
    public loadIcon(){

        var instance = this;

        // construct URL
        var url = 'resources/icons/' + this.name + '.svg';

        // get file over http
        this._http.get(url)

            // Call map on the response observable to get the parsed people object
            .subscribe((res: any) => {

                // assign to DOM
                instance._element.nativeElement.innerHTML = res._body;
            });
    }
    //</editor-fold>

    //<editor-fold desc="EVENTS">
    /**
     * called when @Input changes
     * @param changes {any}
     * */
    ngOnChanges(changes) {

        // check if icon value has changed
        if(changes.name.currentValue != changes.name.previousValue){
            this.loadIcon();
        }
    }
    //</editor-fold>
}