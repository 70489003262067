/**
 * notification levels
 * */
export enum Level {
    Info,
    Warn,
    Error,
    Fatal
}

/**
 * Notification class
 * */
export class Notification {

    // default
    public key: string;
    public level: Level;
    public params: any[];
    public duration: number;
    public text: string;

    /**
     * construct a Notification
     * @param key {string}
     * @param level {number}
     * @param duration {number}
     * */
    constructor(key: string, level: Level, params?: any[], duration?: number){

        this.key = key;
        this.level = level;
        this.params = params;
        this.duration = duration;
    }
}