import {Company} from "./company.model";
/**
 * User class
 * */
export class InventoryNumber {

    // default
    public  _id: string;

    // info
    public name: string;
    public inv_nr: string;
    public company: Company;
    public sort: number;

    constructor(){}
}


