import {Component, ViewEncapsulation, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {SocketService} from "../../infrastructure/socket/socket.service";
import {SessionService} from "../../infrastructure/session/session.service";
import {LocalisationPipe} from "../../infrastructure/pipes/localisation.pipe";
import {ProductService} from "../../infrastructure/server/product.service";
import {UserService} from "../../infrastructure/server/user.service";
import {User} from "../../infrastructure/models/user.model";
import {UserDialogComponent} from "./dialog/user-dialog.component";

import _ from "lodash";

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'users',
    templateUrl: './users.tmpl.html',
    styleUrls: ['./users.sass']
})

/**
 * products component
 * */
export class UsersComponent implements OnInit {
    private _showDialog : boolean = false;

    private _selectedUser: User = new User();

    /**
     * constructs an application component
     * */
    constructor(private _socket: SocketService,
                private _productService: ProductService,
                private _sessionService: SessionService,
                private _userService: UserService,
                private _router: Router){}

    public ngOnInit(){
        if(this._sessionService.isLoggedIn()) {
            // check if cookie set
            this._sessionService.user = this._sessionService.getUser();
            // create a socket connection
            this._sessionService.socketConnect(this._sessionService.user );
            setTimeout(time => {
                //this._mbrService.listen();
                this._userService.loadUsers().subscribe();
            }, 100);
        }else{
            // redirect to login
            setTimeout(time => {
                this._router.navigate(['login']);
            }, 100);
        }
    }

    /**
     *
     * @returns {Mbr[]}
     */
    public getUsers() : User[] {
        return this._userService.users;
    };


    public openDialog(user: User){
        if (user == null){
            if (this._selectedUser){
                delete this._selectedUser._id;
            }
            this._selectedUser.name = "";
            this._selectedUser.password = "";
            this._selectedUser.superAdmin = false;
            this._selectedUser.recipeWriteAdmin = false;
            this._selectedUser.recipeWrite = false;
            this._selectedUser.recipeRead = true;
            this._selectedUser.productWrite = false;
            this._selectedUser.productRead = true;
            this._selectedUser.inventoryWrite = false;
            this._selectedUser.inventoryRead = false;

        }else{
            this._selectedUser = _.cloneDeep(user);
        }
        this._showDialog = true;
    }

    public addOrUpdateUser(user: User){
        this._showDialog = false;
        if (!user.password){
            delete user.password;
        }
        this._userService.createOrUpdateUser(user).subscribe();
    }
}