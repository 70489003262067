import {Component, ViewEncapsulation, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {SocketService} from "../../infrastructure/socket/socket.service";
import {SessionService} from "../../infrastructure/session/session.service";
import {CategoryService} from "../../infrastructure/server/category.service";
import {Recipe} from "../../infrastructure/models/recipe.model";
import {RecipeService} from "../../infrastructure/server/recipe.service";
import {ProductService} from "../../infrastructure/server/product.service";
import {RecipecategoryService} from "../../infrastructure/server/recipecategory.service";
import {Recipecategory} from "../../infrastructure/models/recipecategory.model";

import _ from "lodash";
import * as jsPDF from "jspdf";
import { now } from 'moment';
import { element } from 'protractor';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'offers',
    templateUrl: './offers.tmpl.html',
    styleUrls: ['./offers.sass']
})

/**
 * products component
 * */
export class OffersComponent implements OnInit {
    public _showDialog : boolean = false;
    public _showPrintDialog : boolean = false;
    public printPrice : boolean = true;
    public _selectedOffer: Recipe = new Recipe();

    /**
     * constructs an application component
     * */
    constructor(private _socket: SocketService,
                private _recipeService: RecipeService,
                private _productService: ProductService,
                private _sessionService: SessionService,
                private _categoryService: CategoryService,
                private _recipecategoryService: RecipecategoryService,
                private _router: Router){}

    public ngOnInit(){
        if(this._sessionService.isLoggedIn()) {
            // check if cookie set
            this._sessionService.user = this._sessionService.getUser();
            // create a socket connection
            this._sessionService.socketConnect(this._sessionService.user );
            if (!(this._sessionService.user.recipeRead || this._sessionService.user.recipeWrite || this._sessionService.user.recipeWriteAdmin || this._sessionService.user.superAdmin)){
                this._router.navigate(['login']);
            }
            setTimeout(time => {
                //this._mbrService.listen();
                this._recipeService.loadOffers().subscribe();
                this._recipeService.loadAllRecipes().subscribe();
                this._categoryService.loadCategories().subscribe();
                this._productService.loadAllProducts().subscribe();
                this._recipecategoryService.loadRecipecategories().subscribe();
            }, 100);
        }else{
            // redirect to login
            setTimeout(time => {
                this._router.navigate(['login']);
            }, 100);
        }
    }
    
    public getOffers() : Recipe[] {
        if (this._recipeService.offers){
            let todayDate = new Date();
            todayDate.setHours(0,0,0,0);
            return this._recipeService.offers.filter(a => new Date(a.date) >= todayDate).sort((a: Recipe, b: Recipe) => {
                return new Date(a.date).getTime() - new Date(b.date).getTime();
            });
        }else{
            return [];
        }
    };

    public getOldOffers() : Recipe[] {
        if (this._recipeService.offers){
            let todayDate = new Date();
            todayDate.setHours(0,0,0,0);
            return this._recipeService.offers.filter(a => new Date(a.date) < todayDate).sort((a: Recipe, b: Recipe) => {
                return new Date(b.date).getTime() - new Date(a.date).getTime();
            });
        }else{
            return [];
        }
    };


    public openDialog(recipe: Recipe){
        if (recipe == null){
            if (this._selectedOffer){
                delete this._selectedOffer._id;
            }
            this._selectedOffer.name = "new Offer";
            this._selectedOffer.quantity = 1;
            this._selectedOffer.ingredients = [];
            this._selectedOffer.unit = "u";
            this._selectedOffer.parts = 1;
            this._selectedOffer.description = "";
            this._selectedOffer.recipecategory = null;
            this._selectedOffer.protected = this._sessionService.user.recipeWriteAdmin;
            this._selectedOffer.type = "offer";

            //TODO: all params to ""
        }else{
            this._selectedOffer = _.cloneDeep(recipe);
        }
        this._recipeService.selectedRecipe = this._selectedOffer;
        this._showDialog = true;
    }

    public openPrintDialog(recipe: Recipe){
        this._selectedOffer = _.cloneDeep(recipe);
        this._showPrintDialog = true;
        this.printPrice= true;
    }

    public openPrintDialogRecipe(recipe: Recipe){
        this._selectedOffer = _.cloneDeep(recipe);
        this._showPrintDialog = true;
        this.printPrice = false;
    }

    public addOrUpdateRecipe(offer: Recipe){
        this._showDialog = false;
        this._recipeService.createOrUpdateOffer(offer).subscribe();
    }

    public deleteOffer(offer: Recipe){
        this._showDialog = false;
        this._recipeService.removeOffer(offer).subscribe();
    }

    public  getRecipeTotalPrice(recipe: Recipe): number{
        return this._productService.recipePrice(recipe, recipe.quantity, recipe.unit);
    }


    /*var lineY = actualY + 1;
     doc.setLineWidth(0.5);
     doc.line(20, lineY, 195, lineY);
     doc.setLineWidth(0.5);*/

    public dynamicSort(property: string){
        var sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }
}