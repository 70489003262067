import {Component, ViewEncapsulation, Input, Output, EventEmitter} from '@angular/core';
import 'rxjs/Rx';
import {InventoryNumber} from "../../../infrastructure/models/inventoryNumber.model";

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'inventoryNumber-dialog',
    templateUrl: './inventoryNumber-dialog.tmpl.html',
    styleUrls: ['./inventoryNumber-dialog.sass']
})

/**
 * patients component
 * */
export class InventoryNumberDialogComponent{

    @Input() inventoryNumber: InventoryNumber;
    @Output() closeDialogAndSave:EventEmitter<any> = new EventEmitter();
    @Output() closeDialogAndDelete:EventEmitter<any> = new EventEmitter();
    @Output() closeDialog:EventEmitter<any> = new EventEmitter();

    /**
     * constructs an application component
     * */
    constructor(){}


    public save(){
        this.closeDialogAndSave.emit(this.inventoryNumber);
    }

    public cancel(){
        this.closeDialog.emit(null);
    }

    public delete (){
        var r = confirm("Delete this InventoryNumber?");
        if (r == true) {
            this.closeDialogAndDelete.emit(this.inventoryNumber);
        } 
    }
}