import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {Product} from "../models/product.model";
import {SocketService} from "../socket/socket.service";
import {NotificationService} from "../notification/notification.service";
import {Notification, Level} from "../notification/notification";
import {DateService} from "../date/date.service";
import {FilterService} from "../filter/filter.service";
import {Category} from "../models/category.model";
import {Inventory} from "../models/inventory.model";
import {InventoryProduct} from "../models/inventoryProduct.model";
import _ from "lodash";

@Injectable()

/**
 * event service enables event CRUD
 * */
export class InventoryListService {

    //<editor-fold desc="PROPERTIES">
    public inventories: Inventory[];
    public selectedInventory: Inventory = new Inventory();
    public inventoryProducts: InventoryProduct[];
    //</editor-fold>

    //<editor-fold desc="CONSTRUCTOR">
    /**
     * constructs a Location Service
     * @param _socketService {SocketService}
     * */
    constructor(private _socketService: SocketService,
                private _notificationService: NotificationService,
                private _dateService: DateService,
                private _filterService: FilterService){

    }

    /**
     * loads all locations for current client
     * @returns {Observable<Location[]>}
     */
    public loadInventories() : Observable<Inventory[]>{

        var instance = this;

        return new Observable<Inventory[]>(observer => {

            instance._socketService.socket.emit('inventoryList:load', {}, function(err, inventories){

                if(err){

                    // notify
                    //instance._notificationService.addNotification(new Notification('error_mbrs_loaded', Level.Error));
                    observer.error(err);
                }
                else{
                    //instance._dateService.parseDates(products);
                    instance.inventories = inventories;
                    observer.next(inventories);
                }
            });
        });
    }

    public loadInventoryProducts(inventoryId : string) : Observable<InventoryProduct[]>{

        var instance = this;

        return new Observable<InventoryProduct[]>(observer => {

            instance._socketService.socket.emit('inventoryProducts:load', {inventory: inventoryId}, function(err, inventoryProducts){

                if(err){

                    // notify
                    //instance._notificationService.addNotification(new Notification('error_mbrs_loaded', Level.Error));
                    observer.error(err);
                }
                else{
                    //instance._dateService.parseDates(products);
                    instance.inventoryProducts = inventoryProducts;
                    var index = _.findIndex(instance.inventories, {_id : inventoryId});
                    if (index >= 0){
                        instance.selectedInventory = instance.inventories[index];

                    }
                    observer.next(inventoryProducts);
                }
            });
        });
    }


    public createOrUpdateInventory(inventory: Inventory)  : Observable<Inventory>{
        var instance = this;
        return new Observable<Inventory>(observer => {
            this._socketService.socket.emit('inventoryList:createOrUpdate',{inventory: inventory}, function(err, inventory){
                if(err){
                    observer.error(err);
                }
                else{
                    var index = _.findIndex(instance.inventories, {_id : inventory._id});
                    if (index >= 0){
                        instance.inventories[index] = inventory;

                    }else{
                        instance.inventories.push(inventory);
                    }
                    observer.next(inventory);
                }
            });
        });
    }

    public removeInventory(inventory: Inventory)  : Observable<Inventory>{
        var instance = this;
        return new Observable<Inventory>(observer => {
            // load over web socket
            this._socketService.socket.emit('inventoryList:remove',{inventory: inventory}, function(err, inventory){
                if(err){
                    observer.error(err);
                }
                else{
                    var index = _.findIndex(instance.inventories, {_id : inventory._id});
                    if (index >= 0){
                        instance.inventories.splice(index,1);
                    }
                    observer.next(inventory);
                }
            });
        });
    }


    public pricePerUnit(product: InventoryProduct) : number{
        var price_per_unit: number = 0;

        if (product.price_unit == 'kg' || product.price_unit == 'l'){
            price_per_unit = product.price_per_unit * product.quantity;
        }else if (product.price_unit == 'u'){
            price_per_unit = product.price_per_unit;
        }else if (product.price_unit == 'box'){
            price_per_unit = product.price_per_unit / product.box;
        }
        return price_per_unit;
    }

    public pricePerKg(product: InventoryProduct) : number {
        var price_per_kg_or_l = 0;

        if (product.price_unit == 'kg' || product.price_unit == 'l'){
            price_per_kg_or_l = product.price_per_unit;
        }else if (product.price_unit == 'u'){
            if (product.unit == 'kg' || product.unit == 'l') {
                price_per_kg_or_l = product.price_per_unit / product.quantity;
            }
        }else if (product.price_unit == 'box'){
            if (product.unit == 'kg' || product.unit == 'l') {
                price_per_kg_or_l = product.price_per_unit / product.quantity / product.box;
            }
        }
        return price_per_kg_or_l;
    }


    public pricePerBox(product: InventoryProduct) : number {
        var price_per_box = 0;

        if (product.price_unit == 'kg' || product.price_unit == 'l'){
            price_per_box = product.price_per_unit * product.box * product.quantity;
        }else if (product.price_unit == 'u'){
            price_per_box = product.price_per_unit * product.box;
        }else if (product.price_unit == 'box'){
            price_per_box = product.price_per_unit ;
        }
        return price_per_box;
    }

    public productPrice(product: InventoryProduct, quanity : number, unit : string) : number{
        var price_per_kg_or_l : number = this.pricePerKg(product);
        var price_per_unit : number =  this.pricePerUnit(product);
        var price_per_box : number =  this.pricePerBox(product);

        if (unit == 'kg' || unit == 'l'){
            return price_per_kg_or_l * quanity;
        }else if (unit == 'u'){
            return price_per_unit * quanity;
        }else if (unit == 'box'){
            return price_per_box * quanity;
        }
        return 0;
    }
    //</editor-fold>

    public getProductsOfCategory(category: Category) :InventoryProduct[]{
        var result : InventoryProduct[] = [];
        for (var i = 0; i < this.inventoryProducts.length; i++){
            if (this.inventoryProducts[i].category as any == category._id){
                result.push(this.inventoryProducts[i]);
            }
        }
        return result;
    }

    public getProductsOfCategoryWithQuantity(category: Category) :InventoryProduct[]{
        var result : InventoryProduct[] = [];
        for (var i = 0; i < this.inventoryProducts.length; i++){
            if (this.inventoryProducts[i].category as any == category._id && (this.inventoryProducts[i].inv_box > 0 || this.inventoryProducts[i].inv_rest > 0 || this.inventoryProducts[i].inv_unit > 0)){
                result.push(this.inventoryProducts[i]);
            }
        }
        return result;
    }

    public updateInventoryProduct(product: InventoryProduct)  : Observable<InventoryProduct>{
        var instance = this;
        return new Observable<InventoryProduct>(observer => {
            this._socketService.socket.emit('inventoryProduct:updateInventory',{product: product}, function(err, product){
                if(err){
                    observer.error(err);
                }
                else{
                    var found = false;
                    var index = _.findIndex(instance.inventories, {_id : product._id});
                    if (index >= 0){
                        instance.inventories[index] = product;

                    }else{
                        instance.inventories.push(product);
                    }
                    observer.next(product);
                }
            });
        });
    }
}