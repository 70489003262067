import {Company} from "./company.model";
import {User} from "./user.model";
import {InventoryUser} from "./substructures.model";
import { Division } from "./division.model";
/**
 * User class
 * */
export class Inventory {

    // default
    public  _id: string;

    // info
    public name: string;
    public company: Company;
    public users: InventoryUser[];
    public divisions: Division[];

    constructor(){}
}


