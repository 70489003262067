import {Component, ViewEncapsulation, Input, Output, EventEmitter, OnInit} from '@angular/core';
import 'rxjs/Rx';
import {CategoryService} from "../../../infrastructure/server/category.service";
import {SessionService} from "../../../infrastructure/session/session.service";
import {Router} from "@angular/router";
import {Recipe} from "../../../infrastructure/models/recipe.model";
import {RecipeService} from "../../../infrastructure/server/recipe.service";
import {ProductService} from "../../../infrastructure/server/product.service";

import _ from "lodash";
import * as jsPDF from "jspdf";

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'print-dialog',
    templateUrl: './print-dialog.tmpl.html',
    styleUrls: ['./print-dialog.sass']
})

/**
 * patients component
 * */
export class RecipePrintComponent implements OnInit{
    @Input() recipe: Recipe;
    @Output() closeDialog:EventEmitter<any> = new EventEmitter();
    public quantityToPrint = 1;
    public unitToPrint = "";

    /**
     * constructs an application component
     * */
    constructor(
        private _categoryService: CategoryService,
        private _sessionService: SessionService,
        private _recipeService: RecipeService,
        private _productService: ProductService,
        private _router: Router){
    }


    public ngOnInit(){
        setTimeout(time => {
            //this._mbrService.listen();
            //this._categoryService.loadCategories().subscribe();
            //this._recipeService.loadRecipes().subscribe();
            //this._productService.loadProducts().subscribe();
        }, 200);
        this.quantityToPrint = this.recipe.quantity;
        this.unitToPrint = this.recipe.unit;

    }

    public close(){
        this.closeDialog.emit(null);
    }

    public print(){
        this.printRecipe(this.recipe, this.quantityToPrint, this.unitToPrint);
        this.close();
    }

    public printRecipe(recipe: Recipe, quantity: number, unit: string){
        var splitRegex = /\r\n|\r|\n/g;
        jsPDF.API.textEx = function (text, x, y, hAlign, vAlign) {
            var fontSize = this.internal.getFontSize() / this.internal.scaleFactor;
            y += 0.75;
            // As defined in jsPDF source code
            var lineHeightProportion = 1.15;

            var splittedText = null;
            var lineCount = 1;
            if (vAlign === 'middle' || vAlign === 'bottom' || hAlign === 'center' || hAlign === 'right') {
                splittedText = typeof text === 'string' ? text.split(splitRegex) : text;

                lineCount = splittedText.length || 1;
            }

            // Align the top
            y += fontSize * (2 - lineHeightProportion);

            if (vAlign === 'middle')
                y -= (lineCount / 2) * fontSize;
            else if (vAlign === 'bottom')
                y -= lineCount * fontSize;

            if (hAlign === 'center' || hAlign === 'right') {
                var alignSize = fontSize;
                if (hAlign === 'center')
                    alignSize *= 0.5;

                if (lineCount > 1) {
                    for (var iLine = 0; iLine < splittedText.length; iLine++) {
                        this.text(splittedText[iLine], x - this.getStringUnitWidth(splittedText[iLine]) * alignSize, y);
                        y += fontSize;
                    }
                    return this;
                }
                x -= this.getStringUnitWidth(text) * alignSize;
            }

            this.text(text, x, y);
            return this;
        };

        var quantityFactor = 1;
        if (unit == "p"){
            quantityFactor = quantity / recipe.parts;
        }else{
            quantityFactor = quantity / recipe.quantity;
        }


        var doc = new jsPDF();
        doc.setFont("arial narrow");
        var pageNR = 1;

        var titleHeight = 24;
        var lineHeight = 6;
        var actualY = 0;

        doc.setFontSize(18);
        actualY=actualY+16;
        doc.setFontType("bold");
        doc.text(20, actualY, recipe.name);
        doc.setFontType("normal");
        doc.setFontSize(10);
        actualY=actualY+10;
        doc.text(20, actualY, 'Quantité: '+Number(quantityFactor*recipe.quantity).toFixed(3)+recipe.unit+" ("+Number(quantityFactor*recipe.parts).toFixed(2)+" Portions)");
        //add space
        var indent = 0;
        actualY = this.listIngredientsOfRecipeInPDF(recipe, doc, actualY, quantityFactor, lineHeight, indent);

        var pageHeight= doc.internal.pageSize.getHeight();
        actualY+= 2*lineHeight;
        if (actualY >= pageHeight-20) {
            actualY = 20;
            doc.addPage();
        }
        if(recipe.description && recipe.description.length > 0){
            var splitDescription = doc.splitTextToSize(recipe.description, 180);
            doc.text(20, actualY, splitDescription);
        }
        doc.save(recipe.name+'.pdf');
    }

    public listIngredientsOfRecipeInPDF(recipe, doc, actualY, quantityFactor, lineHeight, indent){
        for (var i = 0; i < recipe.ingredients.length;i++) {
            var ingredient = recipe.ingredients[i];
            if (ingredient.recipe){
                var pageHeight= doc.internal.pageSize.getHeight();
                actualY+= lineHeight+6;//little space for recipes
                if (actualY >= pageHeight-20) {
                    actualY = 20;
                    doc.addPage();
                }
                doc.setFontSize(10);
                doc.setFontType("bold");
                var quantityString = String(Number(ingredient.quantity*quantityFactor).toFixed(3));
                doc.textEx(quantityString,30+indent, actualY, 'right', 'bottom');
                doc.text(31+indent, actualY, ingredient.unit);
                doc.text(40+indent, actualY, ingredient.recipe.name);
                doc.setFontType("normal");

                var ingredientQuantityFactor;
                if (ingredient.unit == "p"){
                    ingredientQuantityFactor = ingredient.quantity / ingredient.recipe.parts;
                }else{
                    ingredientQuantityFactor = ingredient.quantity / ingredient.recipe.quantity;
                }
                var newQuantityFactor = quantityFactor * ingredientQuantityFactor;

                actualY = this.listIngredientsOfRecipeInPDF(ingredient.recipe, doc, actualY, newQuantityFactor, lineHeight, indent+20);
                actualY=actualY+2*lineHeight;
                if(ingredient.recipe.description && ingredient.recipe.description.length > 0){
                    var splitDescription = doc.splitTextToSize(ingredient.recipe.description, 160);
                    doc.text(20+20+indent, actualY, splitDescription);
                    actualY=actualY+12+lineHeight;
                }
            }else if (ingredient.product){
                var pageHeight= doc.internal.pageSize.getHeight();
                actualY+= lineHeight;
                if (actualY >= pageHeight-20) {
                    actualY = 20;
                    doc.addPage();
                }
                doc.setFontSize(10);
                var productDetailString = "";
                if(ingredient.product.sup_name){
                    productDetailString = " ("+ingredient.product.sup_name+", "+ingredient.product.quantity+ingredient.product.unit+")";
                }else{
                    productDetailString = " ("+ingredient.product.quantity+ingredient.product.unit+")";
                }
                var quantityString = String(Number(ingredient.quantity*quantityFactor).toFixed(3));
                doc.textEx(quantityString,30+indent, actualY, 'right', 'bottom');
                doc.text(31+indent, actualY, ingredient.unit);
                doc.text(40+indent, actualY, ingredient.product.product_name + productDetailString);
            }else{
                var pageHeight= doc.internal.pageSize.getHeight();
                actualY+= lineHeight;
                if (actualY >= pageHeight-20) {
                    actualY = 20;
                    doc.addPage();
                }
                doc.setFontSize(10);
                if (ingredient.text){
                    doc.text(30+indent, actualY, ingredient.text);
                }else{
                    doc.text(30+indent, actualY, "");
                }
            }
        }
        return actualY;
    }


}