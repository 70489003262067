import {Component, ViewEncapsulation, Input, Output, EventEmitter} from '@angular/core';
import 'rxjs/Rx';
import {Category} from "../../../infrastructure/models/category.model";
import {SessionService} from "../../../infrastructure/session/session.service";
import {Router} from "@angular/router";
import {InventoryNumber} from "../../../infrastructure/models/inventoryNumber.model";
import { LocalisationPipe } from '../../../infrastructure/pipes/localisation.pipe';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'category-dialog',
    templateUrl: './category-dialog.tmpl.html',
    styleUrls: ['./category-dialog.sass']
})

/**
 * patients component
 * */
export class CategoryDialogComponent{

    @Input() category: Category;
    @Input() inventoryNumbers: InventoryNumber[];
    @Output() closeDialogAndSave:EventEmitter<any> = new EventEmitter();
    @Output() closeDialogAndDelete:EventEmitter<any> = new EventEmitter();
    @Output() closeDialog:EventEmitter<any> = new EventEmitter();

    /**
     * constructs an application component
     * */
    constructor(){}


    public save(){
        this.closeDialogAndSave.emit(this.category);
    }

    public cancel(){
        this.closeDialog.emit(null);
    }

    public delete (){
        var r = confirm("Delete this Category?");
        if (r == true) {
            this.closeDialogAndDelete.emit(this.category);
        } 
    }
}