import {
    Directive, OnInit, ElementRef, Output, EventEmitter, ComponentFactoryResolver,
    ViewContainerRef, Component, ComponentRef, ComponentFactory, Input
} from '@angular/core';
import {SpinnerComponent} from "../spinner/spinner.component";

@Directive({
    selector: '[lazy-loader]'
})

/**
 * Lazy loader
 * */
export class LazyLoader implements OnInit{

    /**
     * callback function to tell component when to load
     */
    @Output() stream: EventEmitter<any> = new EventEmitter<any>();

    /**
     * loading state of the lazy loader
     * */
    @Input() set loading(loading: boolean) {

        this._spinnerComponent.instance.show = loading;
    }

    /**
     * document body
     */
    private _scrollWrapper: any;

    /**
     * the placeholder that can shrink and grow
     */
    private _placeholder: HTMLElement;

    /**
     * elements to load
     * @type {number}
     * @private
     */
    private _elementsToLoad : number = 40;

    /**
     * lower limit of loaded elements
     */
    private _loaded : number = 0;

    /**
     * last time data was loaded (prevents multiple loads when scrolling
     */
    private _lastLoad: number = new Date().getTime();

    /**
     * factory resolver
     */
    private _spinnerComponentFactory : ComponentFactory<SpinnerComponent>;

    /**
     * reference to the spinner component
     */
    private _spinnerComponent: ComponentRef<SpinnerComponent>;

    /**
     * constructs the lazy loader
     * */
    constructor(private _element: ElementRef,
                private _vcRef: ViewContainerRef,
                private _componentFactoryResolver: ComponentFactoryResolver){}
    //<editor-fold desc="METHODS">
    //</editor-fold>

    //<editor-fold desc="EVENTS">
    /**
     * initialises the lazy laoder
     */
    public ngOnInit(){

        // create the growing/shrinking placeholder
        this._placeholder = document.createElement('div');
        this._placeholder.id = 'placeholder';
        this._element.nativeElement.insertBefore(this._placeholder, this._element.nativeElement.firstChild);

        // get the factory
        this._spinnerComponentFactory = this._componentFactoryResolver.resolveComponentFactory(SpinnerComponent);

        // let the factory create the component
        this._spinnerComponent = this._vcRef.createComponent(this._spinnerComponentFactory);

        // get the body
        this._scrollWrapper = document.getElementById('routed-view');

        // register scroll listener on window
        this._scrollWrapper.addEventListener('scroll', ev => {

            // check visibility on scroll
            this._checkScroll();
        });

        // initial load
        this._load(true);
    }

    /**
     * checks if bottom of scroll wrapper has been reached
     */
    private _checkScroll(){

        // get the scroll position
        let scroll = this._scrollWrapper.scrollTop + this._scrollWrapper.getBoundingClientRect().height;

        // get the scrollable height
        let scrollHeight = this._scrollWrapper.scrollHeight;

        // check if bottom reached
        if(scroll + 100 > scrollHeight){

            this._load(false);
        }
    }

    /**
     * loads new elements
     * @private
     */
    private _load(force: boolean){

        // can only load once per second
        if(force || new Date().getTime() > this._lastLoad + 1000){

            // show spinner
            this._spinnerComponent.instance.show = true;

            // callback
            this.stream.emit({initial: force, skip: this._loaded, loaded: () => {

                // hide spinner when done loading
                this._spinnerComponent.instance.show = false;
            }});

            // update loaded elements
            this._loaded += this._elementsToLoad;
        }
    }
    //</editor-fold>
}

