import {Category} from "./category.model";
import {Company} from "./company.model";
import { Division } from "./division.model";

/**
 * User class
 * */
export class Product {

    // default
    public  _id: string;

    public sup_name : string;
    public product_number :  string;
    public product_name :  string;
    public product_description : string;
    public category :  Category;
    public quantity :  number;
    public unit : string;
    public box :  number;
    public price_per_unit : number;
    public price_unit : string;
    public inv_box : number;
    public inv_unit : number;
    public inv_rest : number;
    public company: Company;
    public divisions: Division[];
    
    constructor(){}
}
/*
export interface Product {

    // default
     _id: string;

    sup_name : string;
    product_number :  string;
    product_name :  string;
    product_description : string;
    category :  Category;
    quantity :  number;
    unit : string;
    box :  number;
    price_per_unit : number;
    price_unit : string;
    inv_box : number;
    inv_unit : number;
    inv_rest : number;
    company: Company;
}*/

