import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {SocketService} from "../socket/socket.service";
import {NotificationService} from "../notification/notification.service";
import {DateService} from "../date/date.service";
import {Division} from "../models/division.model";
import _ from "lodash";
@Injectable()

/**
 * event service enables event CRUD
 * */
export class DivisionService {

    //<editor-fold desc="PROPERTIES">
    public divisions: Division[];
    public selectedDivision: Division;
    //</editor-fold>

    //<editor-fold desc="CONSTRUCTOR">
    /**
     * constructs a Location Service
     * @param _socketService {SocketService}
     * */
    constructor(private _socketService: SocketService,
                private _notificationService: NotificationService,
                private _dateService: DateService){

    }

    public getDivisions(): Division[]{
        return this.divisions;
    }

    /**
     * loads all locations for current client
     * @returns {Observable<Location[]>}
     */
    public loadDivisions() : Observable<Division[]>{

        var instance = this;

        return new Observable<Division[]>(observer => {

            // load over web socket
            instance._socketService.socket.emit('division:load', {}, function(err, divisions){

                if(err){

                    // notify
                    //instance._notificationService.addNotification(new Notification('error_mbrs_loaded', Level.Error));
                    observer.error(err);
                }
                else{
                    //instance._dateService.parseDates(products);
                    instance.divisions = divisions;
                    observer.next(divisions);
                }
            });
        });
    }


    public createOrUpdateDivision(division: Division)  : Observable<Division>{
        var instance = this;
        return new Observable<Division>(observer => {
            // load over web socket
            this._socketService.socket.emit('division:createOrUpdate',{division: division}, function(err, division){
                if(err){
                    observer.error(err);
                }
                else{
                    var index = _.findIndex(instance.divisions, {_id : division._id});
                    if (index >= 0){
                        instance.divisions[index] = division;

                    }else{
                        instance.divisions.push(division);
                    }
                    observer.next(division);
                }
            });
        });
    }

    public removeDivision(division: Division)  : Observable<Division>{
        var instance = this;
        return new Observable<Division>(observer => {
            // load over web socket
            this._socketService.socket.emit('division:remove',{division: division}, function(err, division){
                if(err){
                    observer.error(err);
                }
                else{
                    var index = _.findIndex(instance.divisions, {_id : division._id});
                    if (index >= 0){
                        instance.divisions.splice(index,1);
                    }
                    observer.next(division);
                }
            });
        });
    }
}