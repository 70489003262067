import {Component, ViewEncapsulation, Input, Output, EventEmitter} from '@angular/core';
import 'rxjs/Rx';
import {LocalisationPipe} from "../../../infrastructure/pipes/localisation.pipe";
import {Recipecategory} from "../../../infrastructure/models/recipecategory.model";
import {SessionService} from "../../../infrastructure/session/session.service";
import {Router} from "@angular/router";
import {InventoryNumber} from "../../../infrastructure/models/inventoryNumber.model";

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'recipecategory-dialog',
    templateUrl: './recipecategory-dialog.tmpl.html',
    styleUrls: ['./recipecategory-dialog.sass']
})

/**
 * patients component
 * */
export class RecipecategoryDialogComponent{

    @Input() recipecategory: Recipecategory;
    @Input() inventoryNumbers: InventoryNumber[];
    @Output() closeDialogAndSave:EventEmitter<any> = new EventEmitter();
    @Output() closeDialogAndDelete:EventEmitter<any> = new EventEmitter();
    @Output() closeDialog:EventEmitter<any> = new EventEmitter();

    /**
     * constructs an application component
     * */
    constructor(){}


    public save(){
        this.closeDialogAndSave.emit(this.recipecategory);
    }

    public cancel(){
        this.closeDialog.emit(null);
    }

    public delete (){
        var r = confirm("Delete this Recipecategory?");
        if (r == true) {
            this.closeDialogAndDelete.emit(this.recipecategory);
        } 
    }
}