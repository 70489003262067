import {Component, ViewEncapsulation, Input, Output, EventEmitter} from '@angular/core';
import 'rxjs/Rx';
import {LocalisationPipe} from "../../../infrastructure/pipes/localisation.pipe";
import {User} from "../../../infrastructure/models/user.model";

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'user-dialog',
    templateUrl: './user-dialog.tmpl.html',
    styleUrls: ['./user-dialog.sass']
})

/**
 * patients component
 * */
export class UserDialogComponent{

    @Input() user: User;
    @Output() closeDialogAndSave:EventEmitter<any> = new EventEmitter();
    @Output() closeDialog:EventEmitter<any> = new EventEmitter();

    /**
     * constructs an application component
     * */
    constructor(){}


    public save(){
        this.closeDialogAndSave.emit(this.user);
    }

    public cancel(){
        this.closeDialog.emit(null);
    }

}