import {Component, ViewEncapsulation, Input, Output, EventEmitter} from '@angular/core';
import 'rxjs/Rx';
import {Division} from "../../../infrastructure/models/division.model";
import {SessionService} from "../../../infrastructure/session/session.service";
import {Router} from "@angular/router";
import {InventoryNumber} from "../../../infrastructure/models/inventoryNumber.model";
import { LocalisationPipe } from '../../../infrastructure/pipes/localisation.pipe';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'division-dialog',
    templateUrl: './division-dialog.tmpl.html',
    styleUrls: ['./division-dialog.sass']
})

/**
 * patients component
 * */
export class DivisionDialogComponent{

    @Input() division: Division;
    @Output() closeDialogAndSave:EventEmitter<any> = new EventEmitter();
    @Output() closeDialogAndDelete:EventEmitter<any> = new EventEmitter();
    @Output() closeDialog:EventEmitter<any> = new EventEmitter();

    /**
     * constructs an application component
     * */
    constructor(){}


    public save(){
        this.closeDialogAndSave.emit(this.division);
    }

    public cancel(){
        this.closeDialog.emit(null);
    }

    public delete (){
        var r = confirm("Delete this Division?");
        if (r == true) {
            this.closeDialogAndDelete.emit(this.division);
        } 
    }
}