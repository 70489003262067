import {Component, Input, ViewEncapsulation} from '@angular/core';

import _ from "lodash";

/**
 * decorator
 * */
@Component({
    selector: 'spinner',
    templateUrl: './spinner.tmpl.html',
    styleUrls: ['./spinner.sass']
})

/**
 * spinner component
 * */
export class SpinnerComponent{

    public show: boolean = false;

    /**
     * constructs a pie chart component
     * */
    constructor(){}

    //</editor-fold>
}
