import {Component, ViewEncapsulation, Input, Output, EventEmitter} from '@angular/core';
import 'rxjs/Rx';
import {Inventory} from "../../../infrastructure/models/inventory.model";
import {User} from "../../../infrastructure/models/user.model";
import {InventoryUser} from "../../../infrastructure/models/substructures.model";
import { DivisionService } from 'src/app/infrastructure/server/division.service';
import { Division } from 'src/app/infrastructure/models/division.model';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'iventoryList-dialog',
    templateUrl: './inventoryList-dialog.tmpl.html',
    styleUrls: ['./inventoryList-dialog.sass']
})

/**
 * patients component
 * */
export class InventoryListDialogComponent{

    @Input() inventory: Inventory;
    @Input() users: User[];
    @Output() closeDialogAndSave:EventEmitter<any> = new EventEmitter();
    @Output() closeDialog:EventEmitter<any> = new EventEmitter();
    @Output() closeDialogAndDelete:EventEmitter<any> = new EventEmitter();
    /**
     * constructs an application component
     * */
    constructor(private _divisionService: DivisionService){}

    public checkboxList: boolean[] = [];

    public ngOnInit(){
        var instance = this;
        this.users.forEach(function(user){
            user['canEdit'] = false;
            user['readOnly'] = false;
            instance.inventory.users.forEach(function (inventoryUser) {
                if (inventoryUser.userId as any == user._id){
                    user['canEdit'] = true;
                    user['readOnly'] = inventoryUser.readOnly;
                }
            });
        });
        this._divisionService.loadDivisions().subscribe(division => {
            this.calcCheckedList();
        });
    }

    public getUsers(){
        return this.users;
    }

    public save(){
        var allowedUsers : InventoryUser[];
        allowedUsers = [];
        this.users.forEach(function(user){
            if (user['canEdit']){
                var newUser : InventoryUser = new InventoryUser();
                newUser.userId = user._id as any;
                newUser.readOnly = user['readOnly'];
                allowedUsers.push(newUser);
            }
        });
        this.inventory.users = allowedUsers;
        var takeDivisions = [];
        this._divisionService.getDivisions().forEach(division => {
            if(this.checkboxList[division.name]){
                takeDivisions.push(division);
            }
        });
        this.inventory.divisions = takeDivisions;
        this.closeDialogAndSave.emit(this.inventory);
    }

    public cancel(){
        this.closeDialog.emit(null);
    }

    public delete (){
        var r = confirm("Delete this Inventory?");
        if (r == true) {
            this.closeDialogAndDelete.emit(this.inventory);
        }
    }

    public getDivisions(){
        return this._divisionService.getDivisions();
    }

    public calcCheckedList(){
        if(this._divisionService.getDivisions() && this._divisionService.getDivisions().length > 0){
            this._divisionService.getDivisions().forEach(division => {
                this.checkboxList[division.name] = false;
            });
        }
    }
}