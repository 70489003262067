import {Component, ViewEncapsulation, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {SocketService} from "../../infrastructure/socket/socket.service";
import {SessionService} from "../../infrastructure/session/session.service";
import {LocalisationPipe} from "../../infrastructure/pipes/localisation.pipe";
import {ProductService} from "../../infrastructure/server/product.service";
import {Product} from "../../infrastructure/models/product.model";
import {DivisionService} from "../../infrastructure/server/division.service";
import {DivisionDialogComponent} from "./dialog/division-dialog.component";
import {Division} from "../../infrastructure/models/division.model";
import {InventoryNumber} from "../../infrastructure/models/inventoryNumber.model";

import _ from "lodash";
import { Category } from 'src/app/infrastructure/models/category.model';
import { CategoryService } from 'src/app/infrastructure/server/category.service';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'divisions',
    templateUrl: './divisions.tmpl.html',
    styleUrls: ['./divisions.sass']
})

/**
 * products component
 * */
export class DivisionsComponent implements OnInit {
    private _showDialog : boolean = false;

    private _selectedDivision: Division = new Division();
    private _inventoryNumbers: InventoryNumber[] = [];


    public _selectedDivisionMulti: Division = new Division();
    public _selectedCategoryMulti: Category = new Category();
    /**
     * constructs an application component
     * */
    constructor(private _socket: SocketService,
                private _productService: ProductService,
                private _sessionService: SessionService,
                private _divisionService: DivisionService,
                private _categoryService: CategoryService,
                private _router: Router){}

    public ngOnInit(){
        if(this._sessionService.isLoggedIn()) {
            // check if cookie set
            this._sessionService.user = this._sessionService.getUser();
            // create a socket connection
            this._sessionService.socketConnect(this._sessionService.user );
            if (!(this._sessionService.user.superAdmin)){
                this._router.navigate(['login']);
            }
            setTimeout(time => {
                //this._mbrService.listen();
                this._divisionService.loadDivisions().subscribe();
                this._categoryService.loadCategories().subscribe();
                this._productService.loadAllProducts().subscribe();
            }, 100);
        }else{
            // redirect to login
            setTimeout(time => {
                this._router.navigate(['login']);
            }, 100);
        }
    }

    /**
     *
     * @returns {Mbr[]}
     */
    public getDivisions() : Division[] {
        return this._divisionService.getDivisions();
    };

    public getCategories() : Category[]{
        return this._categoryService.getSortedCategories();
    }

    public openDialog(division: Division){
        if (division == null){
            if (this._selectedDivision){
                delete this._selectedDivision._id;
            }
            this._selectedDivision.name = "";
        }else{
            this._selectedDivision = _.cloneDeep(division);
        }
        this._showDialog = true;
    }

    public addOrUpdateDivision(division: Division){
        this._showDialog = false;
        this._divisionService.createOrUpdateDivision(division).subscribe();
    }

    public deleteDivision(division: Division){
        this._showDialog = false;
        this._divisionService.removeDivision(division).subscribe();
    }

    public addCatToDiv(): void{
        this._productService.getProductsOfCategory(this._selectedCategoryMulti).forEach(product =>  {
            let hasDivision = false;
            product.divisions.forEach(storedDivision =>  {
                if (this._selectedDivisionMulti.name == storedDivision.name){
                    hasDivision = true;
                }
            });
            if (!hasDivision){
                product.divisions.push(this._selectedDivisionMulti);
                this._productService.createOrUpdateProduct(product).subscribe();
            }
        });
    }
}