import {Company} from "./company.model";
/**
 * User class
 * */
export class Recipecategory {

    // default
    public  _id: string;

    // info
    public name: string;
    public company: Company;
    public order: number;

    constructor(){}
}


