import {Component, ViewEncapsulation, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {SocketService} from "../../infrastructure/socket/socket.service";
import {SessionService} from "../../infrastructure/session/session.service";
import {CategoryService} from "../../infrastructure/server/category.service";
import {Recipe} from "../../infrastructure/models/recipe.model";
import {RecipeService} from "../../infrastructure/server/recipe.service";
import {ProductService} from "../../infrastructure/server/product.service";
import {RecipecategoryService} from "../../infrastructure/server/recipecategory.service";
import {Recipecategory} from "../../infrastructure/models/recipecategory.model";

import _ from "lodash";
import * as jsPDF from "jspdf";

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'recipes',
    templateUrl: './recipes.tmpl.html',
    styleUrls: ['./recipes.sass']
})

/**
 * products component
 * */
export class RecipesComponent implements OnInit {
    private _showDialog : boolean = false;
    private _showPrintDialog : boolean = false;
    public _selectedRecipe: Recipe = new Recipe();

    /**
     * constructs an application component
     * */
    constructor(private _socket: SocketService,
                private _recipeService: RecipeService,
                private _productService: ProductService,
                private _sessionService: SessionService,
                private _categoryService: CategoryService,
                private _recipecategoryService: RecipecategoryService,
                private _router: Router){}

    public ngOnInit(){
        if(this._sessionService.isLoggedIn()) {
            // check if cookie set
            this._sessionService.user = this._sessionService.getUser();
            // create a socket connection
            this._sessionService.socketConnect(this._sessionService.user );
            if (!(this._sessionService.user.recipeRead || this._sessionService.user.recipeWrite || this._sessionService.user.recipeWriteAdmin || this._sessionService.user.superAdmin)){
                this._router.navigate(['login']);
            }
            setTimeout(time => {
                //this._mbrService.listen();
                this._recipeService.loadRecipes().subscribe();
                this._recipeService.loadAllRecipes().subscribe();
                this._categoryService.loadCategories().subscribe();
                this._productService.loadAllProducts().subscribe();
                this._recipecategoryService.loadRecipecategories().subscribe();
            }, 100);
        }else{
            // redirect to login
            setTimeout(time => {
                this._router.navigate(['login']);
            }, 100);
        }
    }
    
    public getRecipes(recipecategory) : Recipe[] {
        if (this._recipeService.recipes){
            var filteredList = this._recipeService.recipes.filter(function (el) {
                return (el.type != "offer") && (el.recipecategory.toString() == recipecategory._id.toString());
            }.bind(this));
            return filteredList;
        }else{
            return [];
        }
    };

    public getRecipescategories() : Recipecategory[]{
        return this._recipecategoryService.getSortedRecipecategories();
    }


    public openDialog(recipe: Recipe){
        if (recipe == null){
            if (this._selectedRecipe){
                delete this._selectedRecipe._id;
            }
            this._selectedRecipe.name = "new Recipe";
            this._selectedRecipe.quantity = 1;
            this._selectedRecipe.ingredients = [];
            this._selectedRecipe.unit = "kg";
            this._selectedRecipe.parts = 1;
            this._selectedRecipe.description = "";
            this._selectedRecipe.recipecategory = this._recipecategoryService.recipecategories[0];
            this._selectedRecipe.protected = this._sessionService.user.recipeWriteAdmin;

            //TODO: all params to ""
        }else{
            this._selectedRecipe = _.cloneDeep(recipe);
            if (!this._selectedRecipe.recipecategory){
                this._selectedRecipe.recipecategory = this._recipecategoryService.recipecategories[0];
            }
        }
        this._recipeService.selectedRecipe = this._selectedRecipe;
        this._showDialog = true;
    }

    public openPrintDialog(recipe: Recipe){
        this._selectedRecipe = _.cloneDeep(recipe);
        this._showPrintDialog = true;
    }

    public addOrUpdateRecipe(recipe: Recipe){
        this._showDialog = false;
        this._recipeService.createOrUpdateRecipe(recipe).subscribe();
    }

    public deleteRecipe(recipe: Recipe){
        this._showDialog = false;
        this._recipeService.removeRecipe(recipe).subscribe();
    }

    public  getRecipeTotalPrice(recipe: Recipe): number{
        return this._productService.recipePrice(recipe, recipe.quantity, recipe.unit);
    }


    /*var lineY = actualY + 1;
     doc.setLineWidth(0.5);
     doc.line(20, lineY, 195, lineY);
     doc.setLineWidth(0.5);*/

    public dynamicSort(property: string){
        var sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }
}