import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {User} from "../models/user.model";
import {CookieService} from "../cookies/cookie.service";
import {ConfigService} from "../config/confg.service";

import * as io from "socket.io-client";

@Injectable()

export class SocketService {

    //<editor-fold desc="PROPERTIES">
    public socket: any;
    //</editor-fold>

    //<editor-fold desc="CONSTRUCTOR">
    /**
     * constructs a Socket Service
     * @param _configService {ConfigService}
     * */
    constructor(private _router: Router,
                private _cookie: CookieService,
                private _config: ConfigService){}
    //</editor-fold>

    //<editor-fold desc="METHODS">
    /**
     * create a socket connection to the backend
     * */
    public connect(user: User){
        if(this.socket == undefined || !this.socket['connected']) {
            // connect to socket, pass login data in query for handshake
            this.socket = io.connect(this._config.BASE_URL, {
                query: 'name=' + user.name + '&token=' + user.token,
                'connect timeout': 5000
            });

            // check if connected, if not, logout (token not valid anymore)
            setTimeout(time => {

                if (!this.socket['connected']) {
                    // clear the cookie
                    this._cookie.deleteCookie('user');
                    // navigate to login
                    this._router.navigate(['login']);
                    location.reload();
                    //alert('Deine Sitzung ist abgelaufen. Bitte logge doch erneut ein.');
                }

            }, 1000);
        }
    }

    /**
     * emits
     * todo: wnat to use like this?
     * */
    public emit(name: string, load: any, callback: Function){

        // do the actual emitting
        this.socket.emit(name, load, function(err, data){
            callback(err, data);
        });
    }
    //</editor-fold>
}