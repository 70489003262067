import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {SocketService} from "../socket/socket.service";
import {FilterService} from "../filter/filter.service";
import {Recipe} from "../models/recipe.model";
import {Notification, Level} from "../notification/notification";
import {NotificationService} from "../notification/notification.service";
import _ from "lodash";
@Injectable()

/**
 * event service enables event CRUD
 * */
export class RecipeService {

    //<editor-fold desc="PROPERTIES">
    public recipes: Recipe[];
    public recipesAll: Recipe[];
    public offers: Recipe[];

    /**
     * holds the currently selected recipe
     */
    public selectedRecipe: Recipe = new Recipe();
    //</editor-fold>

    //<editor-fold desc="CONSTRUCTOR">
    /**
     * constructs a Location Service
     * @param _socketService {SocketService}
     * */
    constructor(private _socketService: SocketService,
                private _notificationService: NotificationService,
                private _filterService: FilterService){
    }

    /**
     * loads all locations for current client
     * @returns {Observable<Location[]>}
     */
    public loadRecipes() : Observable<Recipe[]>{

        var instance = this;

        return new Observable<Recipe[]>(observer => {

            instance._socketService.socket.emit('recipes:load',  {filter: instance._filterService.filterValueR, category: instance._filterService.recipecategoryid, limit: instance._filterService.limit}, function(err, recipes){

                if(err){

                    // notify
                    //instance._notificationService.addNotification(new Notification('error_mbrs_loaded', Level.Error));
                    observer.error(err);
                }
                else{
                    //instance._dateService.parseDates(products);
                    instance.recipes = recipes;
                    observer.next(recipes);
                }
            });
        });
    }

    public loadOffers() : Observable<Recipe[]>{

        var instance = this;

        return new Observable<Recipe[]>(observer => {

            instance._socketService.socket.emit('recipes:load',  {filter: instance._filterService.filterValueO, category: '', limit: instance._filterService.limit}, function(err, offers){

                if(err){

                    // notify
                    //instance._notificationService.addNotification(new Notification('error_mbrs_loaded', Level.Error));
                    observer.error(err);
                }
                else{
                    var filteredList = offers.filter(function (el) {
                        return (el.type == "offer");
                    }.bind(this));

                    instance.offers = filteredList;
                    observer.next(filteredList);
                }
            });
        });
    }

    public loadAllRecipes() : Observable<Recipe[]>{

        var instance = this;

        return new Observable<Recipe[]>(observer => {

            instance._socketService.socket.emit('recipes:load',  {limit: 99999}, function(err, recipes){

                if(err){

                    // notify
                    //instance._notificationService.addNotification(new Notification('error_mbrs_loaded', Level.Error));
                    observer.error(err);
                }
                else{
                    //instance._dateService.parseDates(products);
                    instance.recipesAll = recipes;
                    observer.next(recipes);
                }
            });
        });
    }

    public createOrUpdateRecipe(recipe: Recipe)  : Observable<Recipe>{
        var instance = this;
        return new Observable<Recipe>(observer => {
            // load over web socket
            this._socketService.socket.emit('recipe:createOrUpdate',{recipe: recipe}, function(err, recipe){
                if(err){
                    observer.error(err);
                }
                else{
                    var found = false;
                    var index = _.findIndex(instance.recipes, {_id : recipe._id});
                    if (index >= 0){
                        instance.recipes[index] = recipe;

                    }else{
                        if (!instance.recipes){
                            instance.recipes = [];
                        }
                        instance.recipes.unshift(recipe);
                    }
                    observer.next(recipe);
                }
            });
        });
    }

    public removeRecipe(recipe: Recipe)  : Observable<Recipe>{
        var instance = this;
        return new Observable<Recipe>(observer => {
            this._socketService.socket.emit('recipe:remove',{recipe: recipe}, function(err, recipe){
                if(err){
                    instance._notificationService.addNotification(new Notification(err, Level.Error));
                    observer.error(err);
                }
                else{
                    var index = _.findIndex(instance.recipes, {_id : recipe._id});
                    if (index >= 0){
                        instance.recipes.splice(index,1);
                    }
                    observer.next(recipe);
                }
            });
        });
    }

    public createOrUpdateOffer(recipe: Recipe)  : Observable<Recipe>{
        var instance = this;
        return new Observable<Recipe>(observer => {
            // load over web socket
            this._socketService.socket.emit('recipe:createOrUpdate',{recipe: recipe}, function(err, recipe){
                if(err){
                    observer.error(err);
                }
                else{
                    var found = false;
                    var index = _.findIndex(instance.offers, {_id : recipe._id});
                    if (index >= 0){
                        instance.offers[index] = recipe;

                    }else{
                        if (!instance.offers){
                            instance.offers = [];
                        }
                        instance.offers.unshift(recipe);
                    }
                    observer.next(recipe);
                }
            });
        });
    }

    public removeOffer(recipe: Recipe)  : Observable<Recipe>{
        var instance = this;
        return new Observable<Recipe>(observer => {
            this._socketService.socket.emit('recipe:remove',{recipe: recipe}, function(err, recipe){
                if(err){
                    instance._notificationService.addNotification(new Notification(err, Level.Error));
                    observer.error(err);
                }
                else{
                    var index = _.findIndex(instance.offers, {_id : recipe._id});
                    if (index >= 0){
                        instance.offers.splice(index,1);
                    }
                    observer.next(recipe);
                }
            });
        });
    }
}