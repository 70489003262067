import {Injectable} from '@angular/core';

@Injectable()

/**
 * client service enables event CRUD
 * */
export class DateService {

    //<editor-fold desc="PROPERTIES">
    //</editor-fold>

    //<editor-fold desc="CONSTRUCTOR">
    /**
     * constructs a Date Service
     * @param _configService {ConfigService}
     * */
    constructor(){}
    //</editor-fold>

    //<editor-fold desc="METHODS">
    public parseDates(input:any){

        var regexDate = /(\d{4})-(\d{2}).(\d{2})/;

        // Ignore things that aren't objects.
        if (typeof input !== "object") return input;

        for (var key in input) {
            if (!input.hasOwnProperty(key)) continue;

            var value = input[key];
            var match;

            // Check for string properties which look like dates
            if (typeof value === "string" && (match = value.match(regexDate))) {

                var date = new Date(value);

                if (date != null) {
                    input[key] = date;
                }
            } else if (typeof value === "object") {

                // Recurse into object
                this.parseDates(value);
            }
        }
    }
    //</editor-fold>
}