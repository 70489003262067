import {Pipe, PipeTransform} from '@angular/core';

@Pipe(
    {
        name: 'dateFormat',
        pure: true
    })

/**
 * localisation pipe
 * */
export class DateFormatPipe implements PipeTransform {

    /**
     * @constructor
     */
    constructor(){}

    /**
     * localises a key
     * */
    public transform(date: Date, arg:string) : string {

        if(date){

            // TODO: check arg 
            return date.toString();
        }
        return '[keyMissing]';
    }

}