import {Pipe, PipeTransform} from '@angular/core';
import {LocalisationService} from "../localisation/localisation.service";

import _ from "lodash";

@Pipe(
    {
        name: 'localise',
        pure: false
    })

/**
 * localisation pipe
 * */
export class LocalisationPipe implements PipeTransform {

    /**
     * @constructor
     */
    constructor(private _localisationService: LocalisationService){}

    /**
     * localises a key
     * */
    public transform(key: string, args:string[]) : string {

        if(key){
            return this._localisationService.getText(key, args)
        }
        return '[keyMissing]';
    }

}