import {Directive, ElementRef, Output, EventEmitter, Input} from '@angular/core';
import {Recipe} from "../models/recipe.model";
import {RecipecategoryService} from "../server/recipecategory.service";

import * as propagating from "propagating-hammerjs";
import _ from "lodash";
 import * as Hammer from "hammerjs";

@Directive({
    selector: '[recipecat-drag]'
})

/**
 * Pipe drag directive
 * */
export class RecipecategoryDrag{
    
    /** hammer time! */
    private hammerTime: any;

    /**
     * constructs an tree drag instance
     * @param _element {ElementRef} holds the element the directive was called upon
     * */
    constructor(private _element: ElementRef,
                private _recipecategoryService : RecipecategoryService){

        var self = this;

        self.hammerTime = propagating(new Hammer(_element.nativeElement, { threshold: 2}));
        self.hammerTime.add( new Hammer.Pan({ direction: Hammer.DIRECTION_ALL, threshold: 2 }) );

        /** initialize the drag */
        self.hammerTime.on('panstart', panStartEv => {

            panStartEv.stopPropagation();
            panStartEv.preventDefault();

            if(panStartEv.target.classList[0] == 'recipecategory-drag-handle'){
                self.itemDrag(panStartEv);
            }

        });
    }
    //<editor-fold desc="METHODS">

    /**
     * drag on item
     * @param panStartEv
     */
    private itemDrag(panStartEv: any){

        var self = this;

        var validTarget = false;
        var dragTargetId;
        var dragTarget;
        var lastTarget;
        
        var dragSourceId = panStartEv.target.parentElement.id;
        var dragSource = _.find(self._recipecategoryService.recipecategories, {_id: dragSourceId});

        // cancel if no drag source found
        if(!dragSource) return;

        dragSource['dragSource'] = true;

        /** catch hammer pan (ie. draging) */
        self.hammerTime.on('panmove', panMoveEv => {

            panMoveEv.stopPropagation();
            panMoveEv.preventDefault();

            var dropAbove = false;

            // check if on container
            if(panMoveEv.target.classList[0] == 'recipecategory-container'){

                validTarget = true;
            }
            // check if on item
            else if(panMoveEv.target.classList[0] == 'recipecategory'){

                dragTargetId = panMoveEv.target.id;
                dragTarget = _.find(self._recipecategoryService.recipecategories, {_id: dragTargetId});

                if(!dragTarget) return;
                validTarget = true;

                /* todo: handle drop position
                 if(dragTarget){
                 var targetOffset = panMoveEv.target.offsetTop + panMoveEv.target.offsetParent.offsetTop + 50 - scrollWrapper.scrollTop();
                 var targetHeight = panMoveEv.target.offsetHeight;
                 var vertDist = panMoveEv.center.y;

                 dropAbove = vertDist < ((targetOffset) + (targetHeight/2));
                 }*/
                dragTarget['dragTarget'] = true;
                if (lastTarget != dragTarget){
                    if (lastTarget){
                        lastTarget['dragTarget'] = false;
                    }
                    lastTarget = dragTarget;
                }
            }
            // no valid target, return


            self.hammerTime.on('panend', function(panEndEvent) {

                panEndEvent.stopPropagation();
                panEndEvent.preventDefault();

                if(validTarget){

                    // todo: create new ingredient

                    if(dragTarget){
                        var sourceOrder = dragSource.order;
                        var targetOrder = dragTarget.order;

                        if(sourceOrder < targetOrder){
                            for (var i = 0; i <  self._recipecategoryService.recipecategories.length; i++) {
                                if (self._recipecategoryService.recipecategories[i].order > sourceOrder && self._recipecategoryService.recipecategories[i].order <= targetOrder){
                                    self._recipecategoryService.recipecategories[i].order--;
                                }
                                self._recipecategoryService.createOrUpdateRecipecategory(self._recipecategoryService.recipecategories[i]).subscribe();
                            }
                            dragSource.order = targetOrder;
                            self._recipecategoryService.createOrUpdateRecipecategory(dragSource).subscribe();
                        }else if(sourceOrder > targetOrder){
                            for (var i = 0; i <  self._recipecategoryService.recipecategories.length; i++) {
                                if (self._recipecategoryService.recipecategories[i].order < sourceOrder && self._recipecategoryService.recipecategories[i].order >= targetOrder){
                                    self._recipecategoryService.recipecategories[i].order++;
                                }
                                self._recipecategoryService.createOrUpdateRecipecategory(self._recipecategoryService.recipecategories[i]).subscribe();
                            }
                            dragSource.order = targetOrder;
                            self._recipecategoryService.createOrUpdateRecipecategory(dragSource).subscribe();
                        }

                    }
                }

                // if drag target, reset
                if(dragTarget){
                    dragTarget['dragTarget'] = false;
                }
                if(dragSource){
                    dragSource['dragSource'] = false;
                }

                validTarget = false;

                self.hammerTime.off('panmove');
                self.hammerTime.off('panend');
            });
        });
    }


    //</editor-fold>

    //<editor-fold desc="EVENTS">
    //</editor-fold>
}