import {Company} from "./company.model";
/**
 * User class
 * */
export class Division {

    // default
    public  _id: string;

    // info
    public name: string;
    public company: Company;

    constructor(){}
}


